import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { DrawAddOptions, GameListItem } from '../../models/games.model';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-draw-form',
  templateUrl: './draw-form.component.html',
  styleUrls: ['./draw-form.component.scss']
})
export class DrawFormComponent implements OnInit {



  constructor(private api: ApiService, private formBuilder: FormBuilder) { }

  @Input() game!: GameListItem;

  @Output() refreshGames = new EventEmitter<GameListItem>();

  drawForm!: FormGroup;

  
  submitted = false;

  onSubmit() {

    if (this.drawForm.valid) {
      this.submitted = true;
      let data = this.drawForm.getRawValue();
      //Convert datetime
      let date: Date = data.firstDraw;
      let arrayTime = data.time.split(":");
      let hours = arrayTime[0];
      let minutes = arrayTime[1];
      date.setHours(date.getHours() + hours);
      date.setMinutes(date.getMinutes() + minutes);

      // Rebuild form data with modified date and time formats.
      let payload = {
        firstDraw: date.toISOString(),
        repeatWeeks: data.repeatWeeks,
        ticketSalesFrom: this.convertToMinutes(data.ticketSalesFrom),
        ticketSalesStop: this.convertToMinutes(data.ticketSalesStop),
        claimDelayMins: this.convertToMinutes(data.claimDelayMins)
      };

      this.api.postGameDrawsPattern(this.game.gameID, payload).subscribe({
        next: (response: any) => {
          this.refreshGames.emit(this.game);
        },
        error: (error: any) => {
          console.log('ERROR', error)

        }
      });
    }
  }

  convertToMinutes(hours: number) {
    return hours * 60;
  }

  ngOnInit(): void {
    this.drawForm = this.formBuilder.group(({
      firstDraw: new FormControl( '', [Validators.required]),
      time: new FormControl('', [Validators.required]),
      repeatWeeks: new FormControl('', [Validators.required, Validators.min(1), Validators.max(524)]),
      ticketSalesFrom: new FormControl('', [Validators.required, Validators.min(3), Validators.max(570)]),
      ticketSalesStop: new FormControl('', [Validators.required, Validators.min(0), Validators.max(72)]),
      claimDelayMins: new FormControl('', [Validators.required, Validators.min(3), Validators.max(72)]),
    }));
  }

  //debug() {
  //  let data = this.drawForm.getRawValue();
  //  //CONVERT TO UTC
  //  let date: Date = data.firstDraw;
  //  let arrayTime = data.time.split(":");
  //  let hours = arrayTime[0];
  //  let minutes = arrayTime[1];
  //  date.setHours(date.getHours() + hours);
  //  date.setMinutes(date.getMinutes() + minutes);
  //  let utcDate = date.toUTCString();
  //  let utcDateISO = date.toISOString();
  //  console.log("firstDraw: ", date);
  //  console.log("UTC:", utcDate, utcDateISO);
  //  console.log("draw form: ", this.drawForm);
  //  console.log("draw form: ", this.drawForm.getRawValue());
  //}

}
