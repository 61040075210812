<mat-drawer-container class="page--wrapper">
  <mat-drawer #drawer (opened)="true" class="column column--left">
    <app-sidebar></app-sidebar>
  </mat-drawer>
  <app-navbar title="Reports" (toggleMenuOutput)="toggleMenu()"></app-navbar>
  <div class="column">
    <app-mat-spinner-overlay *ngIf="isLoading" [overlay]="true">
    </app-mat-spinner-overlay>
    <div class="main--content">
      <div class="dashboard--wrapper">
        <div class="widget--grid games">
          <div class="widget--item span--5">
            <div class="widget--header">
              <div class="title">
                View Report
              </div>
            </div>
            <div class="widget--body">
              <div class="details--list">
                <div class="item">
                  <mat-button-toggle-group (change)="onReportChange($event.value)" aria-label="Report Type">
                    <mat-button-toggle value="Z-Read">Z-Read</mat-button-toggle>
                    <mat-button-toggle value="Claims">Claims</mat-button-toggle>
                    <mat-button-toggle value="Over Time">Over Time</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>

              </div>
            </div>
            <div class="widget--footer">
            </div>
          </div>

          <div *ngIf="isLoadingReportControls" class="widget--item span--5">
            <div style="display: flex; justify-content: center;">
              <app-mat-spinner-overlay [overlay]="false">
              </app-mat-spinner-overlay>
            </div>
          </div>
          <!--Z-Read Report-->
          <div *ngIf="selectedReportName == 'Z-Read' && !isLoadingReportControls" class="widget--item span--5">
            <div class="widget--header">
              <div class="title">
                {{selectedReportName}}
              </div>
            </div>
            <div class="widget--body">
              <div class="details--list">
                <div class="item">

                  <form [formGroup]="zReadForm" (ngSubmit)="onSubmitZReadForm()">
                    <!--<mat-form-field appearance="outline">
                      <mat-label>Format by:</mat-label>
                      <mat-select formControlName="timeFormat" required>
                        <mat-option *ngFor="let format of graphTimeFormats" [value]="format.id">{{format.name}}</mat-option>

                      </mat-select>

                      <mat-error *ngIf="overTimeForm.controls.timeFormat.hasError('required')">Please choose a format.</mat-error>

                    </mat-form-field>
                    <br />-->
                    <mat-form-field appearance="outline">
                      <mat-label>Point of sale ID</mat-label>
                      <input matInput type="text" formControlName="posID" mask="0*">
                    </mat-form-field>
                    <br />
                    <mat-form-field appearance="outline">
                      <mat-label>Enter a date range</mat-label>
                      <mat-date-range-input [formGroup]="zReadForm" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Start date">
                        <input matEndDate formControlName="end" placeholder="End date">
                      </mat-date-range-input>
                      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>

                      <mat-error *ngIf="zReadForm.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>

                      <mat-error *ngIf="zReadForm.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>

                    </mat-form-field>

                    <button mat-button type="submit" class="btn btn-success" [disabled]="zReadForm.controls.start.value == null || zReadForm.controls.end.value == null || !zReadForm.valid">Submit</button>
                    <button mat-button type="button" class="btn btn-default" (click)="zReadForm.reset()">Reset</button>
                  </form>
                </div>
                <div *ngIf="report">
                  <!--<app-line-graph [yAxisName]="this.lineGraphTitle" [data]="this.lineGraphData" [timeData]="this.lineGraphDateFormat"></app-line-graph>-->
                  <div *ngFor="let field of report | keyvalue" class="item">
                    <div class="title">{{field.key}}:</div>
                    <div class="amount">{{field.value}}</div>
                  </div>
                </div>

              </div>
            </div>
            <div class="widget--footer">
            </div>
          </div>
          <!--Claims Report-->
          <div *ngIf="selectedReportName == 'Claims' && !isLoadingReportControls" class="widget--item span--5">
            <div class="widget--header">
              <div class="title">
                {{selectedReportName}}
              </div>
              <div class="date">etc</div>
            </div>
            <div class="widget--body">
              <div class="details--list">
                <div class="item">

                </div>
              </div>

            </div>
            <div class="widget--footer">
            </div>
          </div>

          <!--Sales Report-->
          <div *ngIf="selectedReportName == 'Over Time' && !isLoadingReportControls" class="widget--item span--5">
            <div class="widget--header">
              <div class="title">
                {{selectedReportName}}
              </div>
              <div class="date"></div>
            </div>
            <div class="widget--body">
              <div class="details--list">
                <div class="item">

                  <form [formGroup]="overTimeForm" (ngSubmit)="onSubmitoverTimeForm()">
                    <mat-form-field appearance="outline">
                      <mat-label>Domains:</mat-label>
                      <mat-select formControlName="domains" required>
                        <mat-option *ngFor="let domain of domainList" [value]="domain.organisationID">{{domain.name}}</mat-option>

                      </mat-select>

                      <mat-error *ngIf="overTimeForm.controls.domains.hasError('required')">Please choose a domain.</mat-error>

                    </mat-form-field>
                    <br />
                    <mat-form-field appearance="outline">
                      <mat-label>statistic:</mat-label>
                      <mat-select formControlName="dataFeed" required>
                        <mat-option *ngFor="let dataFeed of salesReportDataFeeds" [value]="dataFeed">{{dataFeed}}</mat-option>

                      </mat-select>

                      <mat-error *ngIf="overTimeForm.controls.timeFormat.hasError('required')">Please choose a statistic.</mat-error>

                    </mat-form-field>
                    <br />
                    <mat-form-field appearance="outline">
                      <mat-label>Format by:</mat-label>
                      <mat-select formControlName="timeFormat" required>
                        <mat-option *ngFor="let format of graphTimeFormats" [value]="format.id">{{format.name}}</mat-option>

                      </mat-select>

                      <mat-error *ngIf="overTimeForm.controls.timeFormat.hasError('required')">Please choose a format.</mat-error>

                    </mat-form-field>
                    <br />
                    <mat-form-field appearance="outline">
                      <mat-label>Enter a date range</mat-label>
                      <mat-date-range-input [formGroup]="overTimeForm" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Start date">
                        <input matEndDate formControlName="end" placeholder="End date">
                      </mat-date-range-input>
                      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>

                      <mat-error *ngIf="overTimeForm.controls.start.hasError('matStartDateInvalid')">Invalid start date.</mat-error>

                      <mat-error *ngIf="overTimeForm.controls.end.hasError('matEndDateInvalid')">Invalid end date.</mat-error>

                    </mat-form-field>

                    <button mat-button type="submit" class="btn btn-success" [disabled]="overTimeForm.controls.start.value == null || overTimeForm.controls.end.value == null || overTimeForm.pristine || !overTimeForm.valid">Submit</button>
                    <button mat-button type="button" class="btn btn-default" (click)="overTimeForm.reset()">Reset</button>
                  </form>

                </div>
                <div *ngIf="isLoadingReport" style="display: flex; justify-content: center;">
                  <app-mat-spinner-overlay [overlay]="false">
                  </app-mat-spinner-overlay>
                </div>
                <div *ngIf="report">
                  <app-line-graph [yAxisName]="this.lineGraphTitle" [data]="this.lineGraphData" [timeData]="this.lineGraphDateFormat"></app-line-graph>
                </div>
              </div>
            </div>
            <div class="widget--footer">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</mat-drawer-container>
