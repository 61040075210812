import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ApiService } from 'app/services/api.service';
@Component({
  selector: 'app-prize-chart',
  templateUrl: './prize-chart.component.html',
  styleUrls: ['./prize-chart.component.scss']
})
export class PrizeChartComponent implements OnInit {
  @Input() set chartData(value: any) {
    // this._allowDay = value;
    // this.updatePeriodTypes();
    // console.log(value);
  }
  @Input() set drawID(value: any) {
    // this._allowDay = value;
    this.getDrawInfo(value);
  }
  list: any[] = [];
  Highcharts: typeof Highcharts = Highcharts;


  chartOptions: Highcharts.Options = {};
  

  

  prizeBreakdowns: any;
  showChart = false;
  
  constructor(
    private _api: ApiService
  ) { }

  ngOnInit(): void {
   
  }

  getDrawInfo(value: any) {
    this.showChart = false;
    this.list = [];
    this._api.getDraw(value).subscribe(drawResult => {
      // console.log(drawResult);
      
      this.prizeBreakdowns = drawResult.prizeTiers;
      this.prizeBreakdowns.forEach((item: any) => {
        this.list.push([item.probability.toFixed(10), item.prizeFundPercentage]);
      });
      // console.log(this.list);


      this.chartOptions = {
        colors: ['#ee2b7f', '#cfa02a', '#35b5e9', '#97aa00', '#262a60',
          '#e30613', '#1d71b8', '#d3d3d3', '#c42525', '#a6c96a'],
        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 45
          }
        },
        title: {
          text: '',
          align: 'left'
        },
        credits: {
          enabled: false
        },
        subtitle: {
          text: '',
          align: 'left'
        },
        plotOptions: {
          pie: {
            innerSize: 100,
            depth: 45
          }
        },
        series: [{
          name: 'Percentage',
          data: this.list
        }] as any
      };

      this.showChart = true;
    });
  }

}
