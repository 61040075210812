import { JsonPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BallTypeInfo, DrawResult, DrawResultAddOptions, GameListItem, GameSetup } from '../../models/games.model';
import { ApiService } from '../../services/api.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-winning-number-form',
  templateUrl: './winning-number-form.component.html',
  styleUrls: ['./winning-number-form.component.scss']
})
export class WinningNumberFormComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private api: ApiService, private _nav: NavService) { }

  @Input() Game!: GameListItem;
  @Input() DrawID!: number;

  @Output() refreshGames = new EventEmitter<DrawResult>();

  gameDetails!: GameSetup;
  drawState!: DrawResult;
;
  loadingForm: boolean = true;
  loadingState: boolean = true;
  winningNumbersForm!: FormGroup;
  userID: number = this._nav.userProfile.userID;
  disableResultButton: boolean = false;
/*  resultToAdd!: DrawResultAddOptions;*/

  ngOnInit(): void {
    this.generateWinningNumberForm();
/*    this.getDrawStatus();*/
  }

  generateWinningNumberForm() {
    this.loadingForm = true;
    this.api.getGame(this.Game.gameID).subscribe(res => {
      this.gameDetails = res;
      this.winningNumbersForm = this.formBuilder.group({});

      this.gameDetails.ballTypes.forEach((ballType) => {
        for (let i = 1; i <= ballType.numberOfBalls; i++) {
          this.winningNumbersForm.addControl(ballType.name + i, new FormControl(undefined , Validators.required))
        }
      })
      this.loadingForm = false;

    });

  }

  onSubmit() {
    let resultToAdd: DrawResultAddOptions = {
      line: []
    };

    let line: number[] = [];
    //Create line.
    this.gameDetails.ballTypes.forEach((balltype) => {
      for (let i = 1; i <= balltype.numberOfBalls; i++) {
        line.push(this.winningNumbersForm.controls[balltype.name + i].value)
      }
    })
    resultToAdd.line = line;
    this.api.postDrawResult(this.DrawID, resultToAdd).subscribe({
      next: (res) => {
        console.log(res);
        this.drawState = res;
        this.checkDrawState();
      },
      error: (err) => {
        console.log("error with winning numbers: ", err);
      }
    })
  }

  undoSubmit() {
    this.disableResultButton = true;
    this.api.removeDrawResult(this.DrawID).subscribe({
      next: (res) => {
        this.getDrawStatus();

      },
      error: (err) => {
        console.log("error removing the first set of numbers: ", err);
        this.disableResultButton = false;
      }
    })

  }

  getDrawStatus() {
    this.loadingState = true;
    this.api.getDrawResult(this.DrawID).subscribe(res => {
      this.drawState = res;
      if (res == null) {
        this.disableResultButton = false;
      }
      this.checkDrawState();
    })
  }

  checkDrawState() {
    if (this.drawState?.isPublished) {
      this.loadingState = false;
      this.refreshGames.emit(this.drawState);
    }
  }

  getBallMatchOptions(numberOfOptions: number, index?: number, ballType?: BallTypeInfo) {
    // Return array of available ball options for individual formControls.
    if (ballType && index) {
      let filter : any[] = [];
      for (let i = 1; i <= ballType.numberOfBalls; i++) {
        // Do not include self in filter.
        if (i != index) {
          filter.push(this.winningNumbersForm.controls[ballType.name + i].value - 1)
        }
      }
      let toReturn = Array(numberOfOptions).fill(0).map((x, i) => i);
      return toReturn.filter(x => filter.indexOf(x) === -1);
    }
    // Return array of numbers equal in length to number provided.
    return Array(numberOfOptions).fill(0).map((x, i) => i);
  }

}
