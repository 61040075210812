import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GameSetup, PrizeTierRequirement } from '../../models/games.model';
import { ApiService } from '../../services/api.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-prize-tier-selector',
  templateUrl: './prize-tier-selector.component.html',
  styleUrls: ['./prize-tier-selector.component.scss']
})
export class PrizeTierSelectorComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private api: ApiService) { }

  @Input() game!: GameSetup;

  prizeTierForms!: FormGroup;
  prizeTierFormsList!: FormArray;
  currentRequirements!: FormArray;
  disable = false;
  currency = environment.currency;


  ngOnInit(): void {
    if (this.game.isPublished) {
      this.disable = this.game.isPublished;
    }



    this.prizeTierFormsList = this.formBuilder.array([]);
    this.prizeTierForms = this.formBuilder.group({ prizeTierFormsList: this.prizeTierFormsList });
    
    if (this.game) {
      if (this.game.prizeTiers.length > 0) {
        this.game.prizeTiers.forEach((prizeTier) => {
          this.prizeTierFormsList.push(this.setPrizeTier(prizeTier, prizeTier.requirements));
        })
      }
    }
}


  setPrizeTier(data: any = null, requirementsList: PrizeTierRequirement[] | null) {
    data = data || { prizeFundPercentage: '', fixedPrizePerLine: null, fixedLimitPerDraw: null, rolloverDraws: 0 };
//    let prizeTier = this.formBuilder.group({
//      prizeFundPercentage: new FormControl({ value: data.prizeFundPercentage, disabled: this.disable }, [Validators.required, Validators.min(0), Validators.max(100), Validators.pattern('[- +()0-9]+')]),
///*      fixedPrizePerLine: new FormControl(data.fixedPrizePerLine, [Validators.required, Validators.min(0), Validators.max(2147483647), Validators.pattern('[- +()0-9]+')]),*/
//      fixedLimitPerDraw: new FormControl({/*Adjust to Naira*/ value: this.getBigDenomination(data.fixedLimitPerDraw), disabled: this.disable }, [Validators.min(0)]),
//      rolloverDraws: new FormControl({ value: data.rolloverDraws, disabled: this.disable }, [Validators.min(0), Validators.max(100), Validators.pattern('[- +()0-9]+')]),
//      requirements: this.formBuilder.array([])
//    })
    let prizeTier: FormGroup;
    if (this.disable) {
      prizeTier = this.formBuilder.group({
        prizeFundPercentage: new FormControl({ value: data.prizeFundPercentage, disabled: this.disable }, [Validators.required, Validators.min(0), Validators.max(100)]),
        /*      fixedPrizePerLine: new FormControl(data.fixedPrizePerLine, [Validators.required, Validators.min(0), Validators.max(2147483647), Validators.pattern('[- +()0-9]+')]),*/
        fixedLimitPerDraw: new FormControl({/*Adjust to Naira*/ value: this.getBigDenomination(data.fixedLimitPerDraw), disabled: this.disable }, [Validators.min(0)]),
        rolloverDraws: new FormControl({ value: data.rolloverDraws, disabled: this.disable }, [Validators.min(0), Validators.max(100), Validators.pattern('[- +()0-9]+')]),
        currentRolloverAmount: new FormControl({ value: this.getBigDenomination(data.currentRolloverAmount), disabled: this.disable }),
        requirements: this.formBuilder.array([])
      })

    } else {
      prizeTier = this.formBuilder.group({
        prizeFundPercentage: new FormControl({ value: data.prizeFundPercentage, disabled: this.disable }, [Validators.required, Validators.min(0), Validators.max(100)]),
        /*      fixedPrizePerLine: new FormControl(data.fixedPrizePerLine, [Validators.required, Validators.min(0), Validators.max(2147483647), Validators.pattern('[- +()0-9]+')]),*/
        fixedLimitPerDraw: new FormControl({/*Adjust to Naira*/ value: this.getBigDenomination(data.fixedLimitPerDraw), disabled: this.disable }, [Validators.min(0)]),
        rolloverDraws: new FormControl({ value: data.rolloverDraws, disabled: this.disable }, [Validators.required, Validators.min(0), Validators.max(100), Validators.pattern('[- +()0-9]+')]),
        requirements: this.formBuilder.array([])
      })
    }
    if (requirementsList) {
      this.currentRequirements = prizeTier.get("requirements") as FormArray;
      requirementsList.forEach((prizeTierRequirement) => {
        this.currentRequirements.push(this.setPrizeTierRequirement(prizeTierRequirement));
      })
    }
    return prizeTier;
  }


  setPrizeTierRequirement(data: any = null) {
    data = data || { ballTypeID: this.game.ballTypes[this.currentRequirements.controls.length].ballTypeID, ordinal: this.currentRequirements.controls.length + 1, matches: null}
    return new FormGroup({
      ballTypeID: new FormControl({ value: data.ballTypeID, disabled: true }, [Validators.required]),
      ordinal: new FormControl({ value: data.ordinal, disabled: true }, [Validators.required, Validators.pattern('[- +()0-9]+')]),
      matches: new FormControl({ value: data.matches, disabled: this.disable }, [Validators.pattern('[- +()0-9]+')])
    })
  }


  getRequirements(index: number) {
    return this.prizeTierFormsList.controls[index].get("requirements") as FormArray;
  }


  addPrizeTier() {
    let prizeTier = this.setPrizeTier(null, null);
    this.currentRequirements = prizeTier.get("requirements") as FormArray;
    this.game.ballTypes.forEach(() => {
      this.currentRequirements.push(this.setPrizeTierRequirement(null));
    })
    this.prizeTierFormsList.push(prizeTier);
  }


  removePrizeTier(index: number) {
    let pTID = this.game.prizeTiers[index]?.prizeTierID
    //If prizeTier has been submitted and has an ID.
    if (pTID) {
      this.api.deletePrizeTier(pTID).subscribe({
        next: (response) => {
          console.log('SUCCESS', response)
          this.prizeTierFormsList.removeAt(index);
        },
        error: (error) => {
          console.log('ERROR', error)
        }
      });
    } else {
      //If prizeTier has never been submitted.
      this.prizeTierFormsList.removeAt(index);
    }
  }


  addPrizeTierRequirement(index: number) {
    this.currentRequirements = this.getRequirements(index);
    this.currentRequirements.push(this.setPrizeTierRequirement(null));
  }

  
  //removeRequirement(RequirementPosition: number, prizeTierIndex: number) {
  //  this.getRequirements(prizeTierIndex).removeAt(RequirementPosition);
  //  this.getRequirements(prizeTierIndex).controls.forEach((req, index) => {
  //    req.patchValue({
  //      ordinal: (index + 1)
  //    })
  //  })
  //}

  getBallMatchOptions(ballIndex: number) {
    return Array(this.game.ballTypes[ballIndex].numberOfBalls).fill(0).map((x, i) => i);
  }


  submitPrizeTier(prizeTier: AbstractControl) {
    let data = prizeTier.getRawValue();

    //Adjust to kobo.
    data.fixedLimitPerDraw = this.getSmallDenomination(data.fixedLimitPerDraw);

      this.api.addPrizeTier(this.game.gameID, data).subscribe({
        next: (response) => {
          console.log('SUCCESS', response)
          prizeTier.markAsPristine();
        },
        error: (error) => {
          console.log('ERROR', error)
        }
      });
  }


  updatePrizeTier(prizeTier: AbstractControl, index: number) {
    let data = prizeTier.getRawValue();

    //Adjust to kobo.
    data.fixedLimitPerDraw = this.getSmallDenomination(data.fixedLimitPerDraw);

    this.api.updatePrizeTier(data, this.game.prizeTiers[index].prizeTierID).subscribe({
      next: (response) => {
        console.log('SUCCESS', response)
        prizeTier.markAsPristine();
      },
      error: (error) => {
        console.log('ERROR', error)
      }
    });
  }


  getSmallDenomination(big: number) {
    return big * 100;
  }

  getBigDenomination(small: number) {
    return small / 100;
  }


  debug(obj: any) {
    console.log("game form: ", obj);
    console.log("game form: ", obj.getRawValue());
  }

}
