import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Page, GameListItem, GameSetup, DrawResult } from 'app/models/games.model';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatDate } from '@angular/common';
import { environment } from 'environments/environment';
import { MatDrawer } from '@angular/material/sidenav';
import { NavService } from '../../services/nav.service';
/*import { animate, state, style, transition, trigger, } from '@angular/animations';*/


@Component({
  selector: 'app-games',
//  animations: [
//    //trigger('growShrinkStaticStart', [
//    //  state('in', style({ height: '*', 'padding-top': '*', 'padding-bottom': '*', 'margin-top': '*', 'margin-bottom': '*' })),
//    //  transition('* => void', [
//    //    //style({ height: '*', 'padding-top': '*', 'padding-bottom': '*', 'margin-top': '*', 'margin-bottom': '*' }),
//    //    animate(1000, style({ opacity: 0 }))
//    //  ]),
//    //  transition('void => false', [
//    //    /*no transition on first load*/
//    //  ]),
//    //  transition('void => *', [
//    //    //style({ height: '0', 'padding-top': '0', 'padding-bottom': '0', 'margin-top': '0', 'margin-bottom': '0' }),
//    //    animate(1000, style({ opacity: 1 }))
//    //  ])
//    //])
//    trigger('fade', [
//      state('void', style({ opacity: 0 })),
///*      state('*', style({ opacity: 1})),*/

//      transition(':enter', [
//        animate(1000)
//      ]),
//      transition(':leave', [
//        animate(0)
//      ])
//    ])
//  ],
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss']
})

export class GamesComponent implements OnInit {
  isLoading: boolean = true;
  isLoadingGameInfo: boolean = false;
  selectedGameItem: GameListItem | undefined;
  selectedGame: GameSetup | undefined;
  showDrawSection: boolean = false;
  showDetailsSection: boolean = false;
  showDrawAwaitingSection: boolean = false;
  showDrawForm: boolean = false;
  games: Page<GameListItem> = {} as Page<GameListItem>;
  draws: any;
  drawsAwaitingResult: any;
  drawDates: Date[] = [];
  highlighter: number = 0;
  winFormId: number | undefined;
  gameIndex: number = 0;
  totalGames: number = 0;
  currency = environment.currency;
  claims = this._nav.userProfile.claims;

  @ViewChild('drawCalendar') calendar!: MatCalendar<Date>;
  @ViewChild('drawer') menu!: MatDrawer;

  constructor(private _api: ApiService, private _nav: NavService, private _snackBar: MatSnackBar, @Inject(LOCALE_ID) private locale: string) { }


  ngOnInit(): void {

    this.getGames();
  }

  toggleMenu() {
    this.menu.toggle();
  }

  getGames(focusTarget?: number, focusDrawSection?: boolean) {
    this.isLoading = true;
    this._api.getGames().subscribe(res => {
      let reverse = res;
      reverse.items = reverse.items.reverse();
      this.totalGames = res.total;
      this.games = reverse;
      this.isLoading = false;
      //If a draft added or updated focus that draft.
      if (focusTarget) {
        if (focusDrawSection) {
          this.viewGameDraws(this.games.items.find((obj) => obj.gameID = focusTarget))
        } else {
          this.viewGameDetails(this.games.items.find((obj) => obj.gameID = focusTarget))
        }
      }
    });
  }

  gamesToShow() {
    let gamesToShow = this.games.items.reverse().slice(this.gameIndex, (this.gameIndex + 4));
    return gamesToShow
  }

  showNextGames() {
    if (this.gameIndex < this.totalGames) {
      this.gameIndex = this.gameIndex + 4;
    }
  }

  showPrevGames() {
    if (this.gameIndex > 0) {
      this.gameIndex = this.gameIndex - 4;
    }
  }

  get filterByPublished() {
    return this.games.items.filter(x => x.isPublished == true);
  }

  viewGameDraws(game: GameListItem | undefined) {
    this.isLoadingGameInfo = true;
    this.hideSections();
    this.draws = undefined;
    this.drawDates = []
    this.selectedGame = undefined;
    this.selectedGameItem = undefined;

    if (game !== undefined) {
      this.selectedGameItem = game
      this._api.getGameDraws(game.gameID).subscribe(res => {
        this.draws = res;
        this.draws.items.forEach((e: any) => {
          this.drawDates?.push(e.drawDate);
        });
        this.calendar.updateTodaysDate();
        this.isLoadingGameInfo = false;
        this.showDrawSection = true;
      })
    }
  }


  viewGameDetails(game: GameListItem | undefined) {
    this.isLoadingGameInfo = true;
    this.hideSections();
    this.selectedGame = undefined;
    this.selectedGameItem = undefined;

    if (game !== undefined) {
      this._api.getGame(game.gameID).subscribe(res => {
        this.selectedGame = res;
        this.isLoadingGameInfo = false;
        this.showDetailsSection = true;
      })
    } else {
      setTimeout(() => {
        this.isLoadingGameInfo = false;
        this.showDetailsSection = true;
      }, 10);
    }
  }

  viewDrawsAwaitingResults(game: GameListItem) {
    this.winFormId = undefined;
    this.isLoadingGameInfo = true;
    this.hideSections();
    this.selectedGameItem = game;

    this._api.getGameDrawsAwaitingResult(game.gameID).subscribe(res => {
      this.drawsAwaitingResult = res;
      this.isLoadingGameInfo = false;
      this.showDrawAwaitingSection = true;
    })
  }

  setWinFormId(id: number) {
    this.winFormId = id;
  }

  hideSections() {
    this.showDetailsSection = false;
    this.showDrawSection = false;
    this.showDrawAwaitingSection = false;
  }

  // Adds an alternating class to a calendar cell if matching a draw date. 
  highlightDrawDate() {
    return (date: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.drawDates
        .map(strDate => new Date(strDate))
        .some(d => d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear());
      if (highlightDate) {
        switch (this.highlighter) {
          case 0:
            this.highlighter++;
            return "special-date1";
          case 1:
            this.highlighter++;
            return "special-date2";
          case 2:
            this.highlighter = 0;
            return "special-date3";
          default:
            return ''
        }
      } else {
        return '';
      }
/*      return highlightDate ? 'special-date' : '';*/
    };
  }

  checkGameCanBePublished(): boolean {
    if (this.drawDates.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  publishGame() {
    if (this.selectedGameItem?.gameID) {
      this._api.publishGame(this.selectedGameItem.gameID).subscribe({
        next: (response) => {
          console.log('SUCCESS', response)
          if (this.selectedGameItem)
          this.gamePublished(this.selectedGameItem);
        },
        error: (error) => {
          console.log('ERROR', error)
        }
      });
    }
  }

  addNewDraws() {
    this.showDrawForm = !this.showDrawForm;
  }


  checkDayIsDraw(array : Date[], value: Date):boolean  {
  return !!array.find(item => { return item == value});
  }

  selectGame(id: number) {

  }

  draftGameAdded(game: GameSetup) {
    this._snackBar.open(game.title + " draft added.", 'X');
    this.getGames(game.gameID);
  }

  draftGameUpdated(title: string) {
    this._snackBar.open(title + " draft updated.", 'X');
    this.getGames();
  }

  removeDraftGame(id: number) {
    this._api.removeDraftGame(id).subscribe(res => {
      this._snackBar.open("Draft removed.", 'X');
      this.getGames();
    })
  }

  gameDrawAdded(game: GameListItem) {
    this._snackBar.open(game.title + " draws have been added.", 'X');
    this.getGames(game.gameID, true);
  }

  gamePublished(game: GameListItem) {
    this._snackBar.open(game.title + " is now live!", 'X');
    this.getGames(game.gameID, true);
  }

  winningNumbersPublished(draw: DrawResult) {
    this._snackBar.open(this.selectedGameItem?.title + " draw " + formatDate(draw.drawnOn, 'medium', this.locale) + " is now processing!", 'X');
    if (this.selectedGameItem) {
      this.viewDrawsAwaitingResults(this.selectedGameItem);
    }
  }

}
