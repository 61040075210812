import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialScratchMeModule } from './material.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './services/interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GamesComponent } from './components/games/games.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { ReportsComponent } from './components/reports/reports.component';
import { MatSpinnerOverlayComponent } from './components/mat-spinner-overlay/mat-spinner-overlay.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { SeparatorDirective } from './directives/separator.directive';
import { PrizeChartComponent } from './components/prize-chart/prize-chart.component';
import { OrganisationsComponent } from './components/organisations/organisations.component';
import { OrganisationFormComponent } from './components/organisation-form/organisation-form.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { DrawFormComponent } from './components/draw-form/draw-form.component';
import { GameFormComponent } from './components/game-form/game-form.component';
import { PrizeTierSelectorComponent } from './components/prize-tier-selector/prize-tier-selector.component';
import { WinningNumbersComponent } from './components/winning-numbers/winning-numbers.component';
import { AutoRefreshComponent } from './components/auto-refresh/auto-refresh.component';
import { WinningNumberFormComponent } from './components/winning-number-form/winning-number-form.component';
import { PrizeBreakdownComponent } from './components/prize-breakdown/prize-breakdown.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { TicketsComponent } from './components/tickets/tickets.component';
import { ClaimsGraphComponent } from './components/claims-graph/claims-graph.component';
import { LineGraphComponent } from './components/line-graph/line-graph.component';
import { PosListComponent } from './components/pos-list/pos-list.component';
import { PosFormComponent } from './components/pos-form/pos-form.component';
import { RoleManagerComponent } from './components/role-manager/role-manager.component';


@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    NavbarComponent,
    DashboardComponent,
    LoginComponent,
    GamesComponent,
    UserMenuComponent,
    ReportsComponent,
    MatSpinnerOverlayComponent,
    SeparatorDirective,
    PrizeChartComponent,
    OrganisationsComponent,
    OrganisationFormComponent,
    UserFormComponent,
    UserListComponent,
    DrawFormComponent,
    GameFormComponent,
    PrizeTierSelectorComponent,
    WinningNumbersComponent,
    AutoRefreshComponent,
    WinningNumberFormComponent,
    PrizeBreakdownComponent,
    TicketsComponent,
    ClaimsGraphComponent,
    LineGraphComponent,
    PosListComponent,
    PosFormComponent,
    RoleManagerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialScratchMeModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
