
<mat-drawer-container class="page--wrapper">
  <mat-drawer #drawer (opened)="true" class="column column--left">
    <app-sidebar></app-sidebar>
  </mat-drawer>
  <div class="column">

    <app-navbar title="Tickets" (toggleMenuOutput)="toggleMenu()"></app-navbar>

    <div class="main-content">
      <div class="widget--grid games">
          <div class="widget--item span--5">
            <div class="widget--header">
              <div class="title">
                <h1>Tickets</h1>
              </div>
            </div>
            <div class="widget--body">
              <form [formGroup]="ticketForm" (ngSubmit)="onSubmit()">

                <mat-form-field appearance="outline">
                  <mat-label>Ref:</mat-label>
                  <input matInput type="text" formControlName="ticketID">
                </mat-form-field>

                <button mat-button type="submit" class="btn btn-success" [disabled]="!ticketForm.valid">Search</button>
                <button mat-button type="button" class="btn btn-default" (click)="ticketForm.reset()">Clear</button>

              </form>
              <div style="display: flex; justify-content: center;">
                <app-mat-spinner-overlay *ngIf="isLoading" [overlay]="false">
                </app-mat-spinner-overlay>
              </div>
              <div *ngIf="selectedTicketDetails">
                <div class="container">
                  <div class="details--list">
                    <div class="item">
                      <div class="title">Ticket Reference Number</div>
                      <div class="amount">{{selectedTicketDetails.ticketID}}</div>
                    </div>
                    <div class="item">
                      <div class="title">Amount</div>
                      <div class="amount">{{currency}} {{(selectedTicketDetails.totalWinnings || 0 ) / 100 | number:'1.0-1' }}</div>
                    </div>
                    <div class="item">
                      <div class="title">Is Central Claim</div>
                      <div class="amount">{{selectedTicketDetails.claimInfo?.isCentral || "No"}}</div>
                    </div>
                    <div class="item">
                      <div class="title">Lines</div>
                      <div class="amount">
                        <div *ngFor="let line of selectedTicketDetails.lines">
                          <div class="inline-flex line-number" *ngFor="let x of line.numbers">
                            {{x}}
                          </div>
                          <div *ngIf="line.winnings">
                            {{currency}} {{line.winnings / 100 | number:'1.0-1' }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="selectedTicketDetails.totalWinnings !== 0">
                    <div class="details--list">

                      <div *ngIf="selectedTicketDetails.claimInfo?.registeredBy !== null">
                        <div class="item">
                          <div class="inline-flex"></div>
                          <div class="title inline-flex">
                            Claimed:
                            <mat-icon color="primary" class="tickIcon">check_box</mat-icon>
                          </div>
                        </div>
                        <div class="item">
                          <div class="title">Registered On</div>
                          <div class="amount">{{selectedTicketDetails.claimInfo?.registeredOn | date: 'dd-MM-YYYY, h:mm a'}}</div>
                        </div>
                        <div class="item">
                          <div class="title">Registered By</div>
                          <div class="amount">{{selectedTicketDetails.claimInfo?.registeredBy}}</div>
                        </div>
                        <div class="item">
                          <div class="title">Registered At</div>
                          <div class="amount">{{selectedTicketDetails.claimInfo?.registeredAt}}</div>
                        </div>
                        <button *ngIf="selectedTicketDetails.claimInfo?.paidOn == null" mat-raised-button color="warn" (click)="removeClaim()" class="ticketButton" [disabled]="isLoadingTicketUpdate">Remove Claim</button>
                      </div>

                      <div *ngIf="selectedTicketDetails.claimInfo?.registeredBy == null">
                        <div class="item">
                          <div class="inline-flex"></div>
                          <div class="title inline-flex">
                            Claimed:
                            <mat-icon color="warn" class="tickIcon">indeterminate_check_box</mat-icon>
                          </div>
                        </div>
                        <button mat-raised-button color="primary" class="ticketButton" (click)="addClaim()" [disabled]="isLoadingTicketUpdate">Add Claim</button>
                      </div>

                    </div>

                    <div class="details--list">
                      <div *ngIf="selectedTicketDetails.claimInfo?.paidOn !== null">
                        <div class="item">
                          <div class="inline-flex"></div>
                          <div class="title inline-flex">
                            Payed out:
                            <mat-icon color="primary" class="tickIcon">check_box</mat-icon>
                          </div>
                        </div>
                        <div class="item">
                          <div class="title">Paid On</div>
                          <div class="amount">{{selectedTicketDetails.claimInfo?.paidOn | date: 'dd-MM-YYYY, h:mm a'}}  </div>
                        </div>
                        <div class="item">
                          <div class="title">Payout Method</div>
                          <div class="amount">{{selectedTicketDetails.claimInfo?.payoutMethod}} </div>
                        </div>
                      </div>
                      <div *ngIf="selectedTicketDetails.claimInfo?.paidOn == null">
                        <div class="item">
                          <div class="inline-flex"></div>
                          <div class="title inline-flex">
                            Paid Out:
                            <mat-icon color="warn" class="tickIcon">indeterminate_check_box</mat-icon>
                          </div>
                        </div>

                      </div>
                      <button *ngIf="selectedTicketDetails.claimInfo?.registeredBy !== null && selectedTicketDetails.claimInfo?.paidOn == null" mat-raised-button color="primary" (click)="markAsPaid()" class="ticketButton" [disabled]="isLoadingTicketUpdate">Mark Paid</button>
                    </div>
                  </ng-container>
                  </div>
                </div>
              <div *ngIf="errorMSG">
                <div class="details--list">
                  <div class="item">
                    <div class="title">Error</div>
                    <div class="amount">{{errorMSG}}</div>
                  </div>
                </div>
              </div>
            </div>

      </div>
    </div>
    </div>


  </div>
  </mat-drawer-container>
