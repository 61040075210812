import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import * as Highcharts from 'highcharts';
import { CurrentGame, DrawResult, GameDrawDate, GameSetup } from '../../models/games.model';
import * as moment from 'moment';
import { environment } from 'environments/environment';
import { MatDrawer } from '@angular/material/sidenav';
import { ReportsService } from '../../services/reports.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  navItems = [
    {
      name: 'Games',
      icon: 'apps',
      url: 'games',
    },
    {
      name: 'Users',
      icon: 'person',
      url: 'users',
    },
    {
      name: 'Reports',
      icon: 'file_open',
      url: 'reports',
    },
    {
      name: 'Logout',
      icon: 'exit_to_app',
      url: 'logout',
    },
  ];


  // donut: Highcharts.Options = {
  //   colors: ['#ee2b7f', '#cfa02a', '#35b5e9', '#97aa00', '#262a60',
  //     '#e30613', '#1d71b8', '#d3d3d3', '#c42525', '#a6c96a'],
  //   chart: {
  //     type: 'pie',
  //     options3d: {
  //       enabled: true,
  //       alpha: 45
  //     }
  //   },
  //   title: {
  //     text: '',
  //     align: 'left'
  //   },
  //   credits: {
  //     enabled: false
  //   },
  //   subtitle: {
  //     text: '',
  //     align: 'left'
  //   },
  //   plotOptions: {
  //     pie: {
  //       innerSize: 100,
  //       depth: 45
  //     }
  //   },
  //   series: [{
  //     name: 'Percentage',
  //     data: [
  //       ['0.00000000794', 40],
  //       ['0.00000015891', 4],
  //       ['0.00000035755', 1],
  //       ['0.00000174805', 1],
  //       ['0.00003496104', 1.5],
  //       ['0.00007516625', 1],
  //       ['0.00007866236', 1],
  //       ['0.00105232760', 4.5],
  //       ['0.00150332514', 2],
  //       ['0.00338248157', 4],
  //       ['0.00539317895', 5],
  //       ['0.02104655203', 15],
  //       ['0.04735474208', 20],

  //     ]
  //   }] as any
  // };

  //line1: Highcharts.Options = {
  //  colors: ['#ee2b7f', '#cfa02a', '#35b5e9', '#97aa00', '#262a60',
  //    '#e30613', '#1d71b8', '#d3d3d3', '#c42525', '#a6c96a'],
  //  title: {
  //    text: '',
  //    align: 'left'
  //  },
  //  credits: {
  //    enabled: false
  //  },
  //  yAxis: {
  //    title: {
  //      text: 'Number of Tickets'
  //    }
  //  },

  //  xAxis: {
  //    type: 'datetime'
  //    // accessibility: {
  //    //   rangeDescription: 'Range: 2010 to 2020'
  //    // }
  //  },

  //  legend: {
  //    layout: 'vertical',
  //    align: 'right',
  //    verticalAlign: 'middle'
  //  },

  //  plotOptions: {
  //    series: {
  //      label: {
  //        connectorAllowed: false
  //      },
  //      pointStart: Date.UTC(2023, 3, 31, 6, 0, 0, 0),
  //      pointInterval: 24 * 3600 * 1000
  //    }
  //  },

  //  series: [{
  //    name: 'Draw 1',
  //    data: [43934, 48656, 65165, 81827, 112143, 142383,
  //      171533, 165174, 155157, 161454, 154610]
  //  }, {
  //    name: 'Draw 2',
  //    data: [24916, 37941, 29742, 29851, 32490, 30282,
  //      38121, 36885, 33726, 34243, 31050]
  //  }, {
  //    name: 'Draw 3',
  //    data: [11744, 30000, 16005, 19771, 20185, 24377,
  //      32147, 30912, 29243, 29213, 25663]
  //  }, {
  //    name: 'Draw 4',
  //    data: [null, null, null, null, null, null, null,
  //      null, 11164, 11218, 10077]
  //  }, {
  //    name: 'Draw 5',
  //    data: [21908, 5548, 8105, 11248, 8989, 11816, 18274,
  //      17300, 13053, 11906, 10073]
  //  }] as any,
  //};

  //pie1: Highcharts.Options = {
  //  colors: ['#ee2b7f', '#cfa02a', '#35b5e9', '#97aa00', '#262a60',
  //    '#e30613', '#1d71b8', '#d3d3d3', '#c42525', '#a6c96a'],
  //  credits: {
  //    enabled: false
  //  },
  //  chart: {
  //    plotBorderWidth: 0,
  //    plotShadow: false
  //  },
  //  title: {
  //    text: 'Claimed<br>vs<br>Unclaimed',
  //    align: 'center',
  //    verticalAlign: 'middle',
  //    y: 60
  //  },
  //  tooltip: {
  //    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
  //  },
  //  accessibility: {
  //    point: {
  //      valueSuffix: '%'
  //    }
  //  },
  //  plotOptions: {
  //    pie: {
  //      dataLabels: {
  //        enabled: true,
  //        distance: -50,
  //        style: {
  //          fontWeight: 'bold',
  //          color: 'white'
  //        }
  //      },
  //      startAngle: -90,
  //      endAngle: 90,
  //      center: ['50%', '75%'],
  //      size: '110%'
  //    }
  //  },
  //  series: [{
  //    type: 'pie',
  //    name: 'Tickets',
  //    innerSize: '50%',
  //    data: [
  //      ['Claimed', 73.86],
  //      ['Unclaimed', 26.14],
  //    ]
  //  }]
  //};

  drawList!: GameDrawDate[] | null;
  selectedDrawIndex = 0;
  selectedHistoricDrawIndex = 0;
  historicDrawList: GameDrawDate[] | null = [];
  pricePerTicket = 0;
  gameID = 0;
  currentGameList: CurrentGame[] = [];
  currentGame: CurrentGame | undefined;
  currentGameIndex = 0;
  currentDraw: any;
  gameDetails: GameSetup | undefined;
  drawResult: DrawResult | undefined;
  prizeBreakdowns: any;
  isLoading = true;
  isLoadingDraws = false;
  isLoadingGameDetails = false;
  isLoadingHistoricDraws = false;
  isLoadingDrawResult = true;
  isLoadingFutureDraw = false;
  currency = environment.currency;
  totalWinners = 0
  showExtraInfo = false;
  @ViewChild('drawer') menu!: MatDrawer;

  today = new Date();
  constructor(
    private _api: ApiService,
    private _router: Router,
    private _auth: AuthService,
    private _rapi: ReportsService,
    private _nav: NavService
  ) { }

  ngOnInit(): void {
    //this._api.getGames().subscribe(results => {
    //  // TODO handle game to display logic.
    //  this.firstGame = results.items[results.items.length - 1];
    //  // console.log(this.firstGame);
    //});
    this.getCurrentGame();
  }

  toggleMenu() {
    this.menu.toggle();
  }


  getCurrentGame() {
    this._api.getCurrentGame().subscribe(result => {
      this.currentGameList = result.items;

      let closestGameDate: Date;
      this.currentGameList.forEach((game, index) => {
        if (game.drawDates) {
          if (game.drawDates[0]?.drawDate) {
            if (new Date(game.drawDates[0].drawDate) < closestGameDate || closestGameDate == undefined) {
              closestGameDate = new Date(game.drawDates[0].drawDate);
              this.currentGameIndex = index;
            }
          }
        }
      })
      this.selectCurrentGame();
    });
  }

  getNextGame() {
    if (this.currentGameList[this.currentGameIndex + 1] !== undefined) {
      this.currentGameIndex++;
      this.selectCurrentGame();
    } else {
      this.currentGameIndex = 0;
      this.selectCurrentGame();
    }
  }

  selectCurrentGame() {
    if (!this.currentGameList.length) {
      this.isLoading = false;
    } else {
    this.currentGame = this.currentGameList[this.currentGameIndex];
    this.pricePerTicket = this.currentGame.pricePerTicket / 100;

      if (!this.isLoading) {
      this.setShowExtraInfo(false);
      this.isLoadingDraws = true;
      this.isLoadingGameDetails = true;
      this.isLoadingHistoricDraws = true;
      }

      this.getGameDraws(this.currentGame.gameID);
      // TODO should orgman have access to these calls?
/*      if (this._nav.userProfile.role == 'rolemaster') {*/
        this.getGameDetails(this.currentGame.gameID);
        this.getHistoricGameDraws(this.currentGame.gameID, 1);
/*      }*/
    }
  }

  checkLoading() {
    if (!this.isLoadingDraws && !this.isLoadingGameDetails && !this.isLoadingHistoricDraws) {
      this.isLoading = false;
    }
  }

  getGameDraws(id: number) {
    this._api.getGameDraws(id).subscribe(draws => {
      this.drawList = draws.items;
      this.selectedDrawIndex = 0;
      this.isLoadingDraws = false;
      this.checkLoading();
    });
  }

  selectNextDraw() {
    if (this.drawList) {
      this.isLoadingFutureDraw = true;
      if (this.drawList[this.selectedDrawIndex + 1] != undefined && this.selectedDrawIndex < 3) {
        this.selectedDrawIndex++;
      } else {
        this.selectedDrawIndex = 0;
      }
      setTimeout(() => {
        this.isLoadingFutureDraw = false;
      }, 250);

    }
  }

  selectNextHistoricDraw() {
    if (this.historicDrawList) {
      if (this.historicDrawList[this.selectedHistoricDrawIndex + 1] != undefined && this.selectedHistoricDrawIndex < 3) {
        this.selectedHistoricDrawIndex++;
      } else {
        this.selectedHistoricDrawIndex = 0;
      }
      this.getDrawResult(this.historicDrawList[this.selectedHistoricDrawIndex].drawID);
    }
  }

  getHistoricGameDraws(id: number, months: number) {
    let today = new Date();
    let aMonthsBeforeTime = moment(today);
    aMonthsBeforeTime.subtract(months, "months");
    //this._api.getAllDrawResults(id).subscribe({
    //  next: (res) => {
    //    console.log("Res: ", res);
    //  },
    //  error: (err) => {
    //    console.log("error: ", err);
    //  }
    //})

    this._api.getGameDraws(id, aMonthsBeforeTime, today).subscribe(draws => {
      if (draws.items.length !== 0) {
        this.historicDrawList = draws.items.reverse();
        this.getDrawResult(this.historicDrawList[0].drawID);
      }
    });

  }

  getDrawResult(id: number) {
    this.isLoadingDrawResult = true;
    this.setShowExtraInfo(false);
    this.totalWinners = 0;
    this._api.getDrawResult(id).subscribe(res => {
      this.drawResult = res;
      this.totalWinners = this.drawResult?.prizeTiers.reduce((accum, item) => accum + item.winningTickets, 0);
      this._rapi.getClaimState(id).subscribe(res => {
        console.log("Claim State = ", res);
      })
      this.isLoadingHistoricDraws = false;
      this.isLoadingDrawResult = false;
      this.checkLoading();
    })
  }

  getGameDetails(id: number) {
    this._api.getGame(id).subscribe(gameDetails => {
      this.gameDetails = gameDetails;
      this.isLoadingGameDetails = false;
    })
  }

  formatWinNum(index: number) {
    if (this.gameDetails !== undefined) {
      let toSkip = 0;
      for (let i = 0; i < index; i++) {
        toSkip = toSkip + this.gameDetails.ballTypes[i].numberOfBalls;
      }
      let formedNums = this.drawResult?.numbers.slice(toSkip, toSkip + this.gameDetails.ballTypes[index].numberOfBalls);

      return formedNums;
    }
    else {
      return [];
    }
  }

  navigateTo(url: string) {
    if (url === 'logout') {
      this._auth.logout();
      this._router.navigate(['login']);
    }
    else {
      this._router.navigate([url]);
    }
  }

  setShowExtraInfo(b: boolean) {
    if (this.showExtraInfo == true && b == true) {
      this.showExtraInfo = false;
    } else {
      this.showExtraInfo = b;
    }
  }
}
