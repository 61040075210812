import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'app/services/auth.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username = '';
  password = '';
  currentApplicationVersion = environment.appVersion;

  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });

  constructor(
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  login() {
    this.username = this.loginForm.controls.username.value || '';
    this.password = this.loginForm.controls.password.value || '';
    this._authService.login(this.username, this.password);
  }

}
