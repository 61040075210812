import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Organisation, OrganisationAddOptions, Region } from '../../models/onboarding.model';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-organisations',
  templateUrl: './organisations.component.html',
  styleUrls: ['./organisations.component.scss']
})
export class OrganisationsComponent implements OnInit {

  selected: Organisation = {} as Organisation;
  selectedRegions: Region[] = [];
  showNewOrgForm = false;

  dataSource!: MatTableDataSource<Organisation>;
  displayedColumns: string[] = ['id', 'name', 'reference', 'posCount'];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private _api: ApiService) { }


  ngOnInit(): void {
    this.getOrganisations();
  }

  getOrganisations() {
    this._api.getOrganisations().subscribe(result => {
      console.log(result);
      this.dataSource = new MatTableDataSource(result.items);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  select(org: Organisation) {
    this.selected = org;

    this._api.getRegions(this.selected.organisationID).subscribe(result => {
      this.selectedRegions = result.items;
    })
  }

  addNewOrg() {
    this.showNewOrgForm = !this.showNewOrgForm;
  }

}
