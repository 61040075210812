<div class="widget--grid">
  <div class="widget--item">
    <div [hidden]="submitted">
      <div class="widget--header">
        <h1>Add New Organisation</h1>
      </div>
      <div class="widget--body">
        <form [formGroup]="organisationForm" (ngSubmit)="onSubmit()">

          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name">
          </mat-form-field>

          <!--<mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="email" [(ngModel)]="org.emailAddress">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Phone number</mat-label>
            <input matInput type="tel" formControlName="phone" [(ngModel)]="org.phoneNumber">
          </mat-form-field>-->

          <mat-form-field appearance="outline">
            <mat-label>Address</mat-label>
            <input matInput formControlName="address">
          </mat-form-field>

          <!--<mat-form-field appearance="outline">
            <mat-label>City</mat-label>
            <input matInput formControlName="city" [(ngModel)]="org.address.city">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>PostCode</mat-label>
            <input matInput formControlName="postcode" [(ngModel)]="org.address.postalCode">
          </mat-form-field>-->

          <mat-form-field appearance="outline">
            <mat-label>authRef</mat-label>
            <input matInput formControlName="authRef">
          </mat-form-field>

          <button mat-button type="submit" class="btn btn-success" [disabled]="!organisationForm.valid">Submit</button>
          <button mat-button type="button" class="btn btn-default" (click)="organisationForm.reset()">Reset</button>
        </form>
      </div>
    </div>

    <div [hidden]="!submitted">
      <div class="widget--header">
        <h2>You submitted the following:</h2>
      </div>
      <div class="widget--body">
        <div class="row">
          <div class="col-xs-3">Name</div>
          <div class="col-xs-9">{{ org.name }}</div>
        </div>
        <div class="row">
          <div class="col-xs-3">Phone Number</div>
          <div class="col-xs-9">{{ org.phoneNumber }}</div>
        </div>
        <div class="row">
          <div class="col-xs-3">Address line 1</div>
          <div class="col-xs-9">{{organisationForm }}</div>
        </div>
        <br>
        <button type="button" class="btn btn-primary" (click)="submitted=false">Edit</button>
      </div>
    </div>
  </div>
</div>
