import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PointOfSaleAddOptions } from '../../models/onboarding.model';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-pos-form',
  templateUrl: './pos-form.component.html',
  styleUrls: ['./pos-form.component.scss']
})
export class PosFormComponent {


  constructor(private _api: ApiService,) { }

  /*  @Input() user: UserAddOptions = {} as UserAddOptions;*/


  @Input() selectedID!: number;

  userForm = new FormGroup({
    reference: new FormControl('', [Validators.required, Validators.min(1), Validators.maxLength(36)]),
    locationDescription: new FormControl('', [Validators.required, Validators.min(1), Validators.maxLength(36)]),
    name: new FormControl('', [Validators.required, Validators.min(1), Validators.maxLength(36)]),
    username: new FormControl('', [Validators.required, Validators.min(1), Validators.maxLength(36)]),
    passwordB64: new FormControl('', [Validators.required]),
    passwordB642: new FormControl('', [Validators.required])
  });

  submitted = false;
  errorMsg = '';

  ngOnInit(): void {
  }

  onSubmit() {

    if (this.userForm.valid) {
      if (this.userForm.controls.passwordB64.getRawValue() == this.userForm.controls.passwordB642.getRawValue()) {
        this.submitted = true;

        let orgUser: PointOfSaleAddOptions = {
          reference: this.userForm.controls.name.value || "",
          locationDescription: this.userForm.controls.name.value || "",
          user: {
            name: this.userForm.controls.name.value || "",
            username: this.userForm.controls.username.value || "",
            passwordb64: btoa(this.userForm.controls.passwordB64.value || "")
          }
        }
        this._api.postPoS(orgUser).subscribe({
          next: (response) => {
            console.log('SUCCESS', response)
          },
          error: (error) => {
            console.log('ERROR', error)
          }
        });

      } else {
        this.errorMsg = "Passwords do not match"
      }
    }

  }

}
