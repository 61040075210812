import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OrgManAddOptions, OrgUserAddOptions, UserAddOptions } from '../../models/onboarding.model';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {

  ListType = [
    {
      name: 'User'
    },
    {
      name: 'Organisation Manager'
    }
  ];

  constructor(private _api: ApiService,) { }

  @Input() user: UserAddOptions = {} as UserAddOptions;

  //This is used to select the position of ListType settings.
  @Input() mode!: number;

  @Input() selectedID!: number;

  userForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.min(1), Validators.maxLength(36)]),
    username: new FormControl('', [Validators.required, Validators.min(1), Validators.maxLength(36)]),
/*    email: new FormControl('', [Validators.maxLength(255), Validators.email]),*/
    phone: new FormControl('', [Validators.maxLength(16), Validators.pattern('[- +()0-9]+')]),
    passwordB64: new FormControl('', [Validators.required]),
    passwordB642: new FormControl('', [Validators.required])
  });

  submitted = false;
  errorMsg = '';

  ngOnInit(): void {
  }

  onSubmit() {

    if (this.userForm.valid) {
      if (this.userForm.controls.passwordB64.getRawValue() == this.userForm.controls.passwordB642.getRawValue()) {
        this.submitted = true;
        //Use endpoint based on mode
        switch (this.mode) {
          case 0: {
            let orgUser: OrgUserAddOptions = {
              domainID: this.selectedID,
                name: this.userForm.controls.name.value || "",
              username: this.userForm.controls.username.value || "",
              phoneNumber: this.userForm.controls.phone.value || "",
              passwordB64: btoa(this.userForm.controls.passwordB64.value || "")
            }
            this._api.postOrganisationUser(this.selectedID, orgUser).subscribe({
              next: (response) => {
                console.log('SUCCESS', response)
              },
              error: (error) => {
                console.log('ERROR', error)
              }
            });
            break;
          }
          case 1: { //ORGANISATION MANAGER;
            let orgMan: OrgManAddOptions = {
              domainID: this.selectedID,
              name: this.userForm.controls.name.value || "",
              username: this.userForm.controls.username.value || "",
              phoneNumber: this.userForm.controls.phone.value || "",
              passwordB64: btoa(this.userForm.controls.passwordB64.value || "")
            }
            this._api.postOrganisationManager(this.selectedID, orgMan).subscribe({
              next: (response) => {
                console.log('SUCCESS', response)
              },
              error: (error) => {
                console.log('ERROR', error)
              }
            });
            break;
          }
          default: {
            //statements; 
            break;
          }

        }
      } else {
        this.errorMsg = "Passwords do not match"
      }
    }


  }

}
