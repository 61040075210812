import { Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserInfo } from '../../models/onboarding.model';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-pos-list',
  templateUrl: './pos-list.component.html',
  styleUrls: ['./pos-list.component.scss']
})
export class PosListComponent {

  dataSource!: MatTableDataSource<UserInfo>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = ['pointOfSaleID', 'reference', 'addedOn'];
  showAddPosForm = false;

  constructor(private _api: ApiService,) { }

  //0 = rolemaster, 1 = orgMan.
  @Input() mode!: number;

  @Input() selectedID!: number;

  selected?: number;

  ngOnInit(): void {
    this.load();
  }
  ngOnChanges(): void {
    this.load();
  }

  load() {
    this.getOrganisationPos();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  select(id: any) {
    this.selected = id;
  }

  addNewPos() {
    this.showAddPosForm = !this.showAddPosForm;
  }

  getOrganisationPos() {
    this._api.getPoSByOrg(this.selectedID).subscribe(result => {
      this.dataSource = new MatTableDataSource(result.items);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

}
