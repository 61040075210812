import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TicketClaimInfo, TicketPrizeBreakdown } from '../models/games.model';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketingApiService {

  private _apiBaseUrl = environment.baseUrl;

  constructor(private _http: HttpClient) { }


  public getTicketPrize(ticketID: string): Observable<TicketPrizeBreakdown> {
    return this._http.get<TicketPrizeBreakdown>(
      `${this._apiBaseUrl}/ticketing/ticket/${ticketID}/prize`
    );
  }

  public postClaim(ticketID: string): Observable<TicketClaimInfo> {
    return this._http.post<TicketClaimInfo>(
      `${this._apiBaseUrl}/ticketing/ticket/${ticketID}/claim`, null
    );
  }

  public removeClaim(ticketID: string): Observable<any> {
    return this._http.delete(
      `${this._apiBaseUrl}/ticketing/ticket/${ticketID}/claim`
    );
  }

  public markTicketPaid(ticketID: string): Observable<any> {
    return this._http.post(
      `${this._apiBaseUrl}/ticketing/ticket/${ticketID}/payout`, null
    );
  }


}
