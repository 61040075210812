import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private _apiBaseUrl = environment.baseUrl;
  constructor(private _http: HttpClient) { }

  public getClaimState(drawID: number): Observable<any> {
    return this._http.get<any>(
      `${this._apiBaseUrl}/reports/internal/draw/${drawID}/claim-state`
    );
  }

  public getPoSZRead(posID: any, start: any, end: any): Observable<any> {
    return this._http.get<any>(
      `${this._apiBaseUrl}/reports/internal/pos/${posID}/z-read?start=${start}&end=${end}`
    );
  }

  public getDomainReportByDate(domainID: number, start: any, end: any): Observable<any> {
    return this._http.get<any>(
      `${this._apiBaseUrl}/reports/internal/domain/${domainID}/report?start=${start}&end=${end}`
    );
  }

  public getDomainSalesOverTime(domainID: number, start: any, until: any, resolution: number, drawID: number | null): Observable<any> {
    if (drawID == null) {
      return this._http.get<any>(
        `${this._apiBaseUrl}/reports/internal/domain/${domainID}/over-time?from=${start}&until=${until}&resolution=${resolution}`
      );
    } else {
      return this._http.get<any>(
        `${this._apiBaseUrl}/reports/internal/domain/${domainID}/over-time?from=${start}&until=${until}&resolution=${resolution}&drawID=${drawID}`
      );
    }
    
  }
}
