import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import { Organisation } from '../../models/onboarding.model';
import { ApiService } from '../../services/api.service';
import { NavService } from '../../services/nav.service';
import { ReportsService } from '../../services/reports.service';
import { environment } from 'environments/environment';



@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  // View and load variables
  isLoading = false;
  isLoadingReportControls = false;
  isLoadingReport = false;
  showReportWidget = false;

  //Selected report variables
  selectedReportName: string | undefined;
  report: any[] | undefined;
  lineGraphTitle: any;
  lineGraphDateFormat: any;
  lineGraphData: any[] = [];
  graphTimeFormats = [
    { name: 'Hour', id: 1 },
    { name: 'Day', id: 2 },
    { name: 'Week', id: 3 },
    { name: 'Month', id: 4 },
  ];
  salesReportDataFeeds = ['Claims', 'Income', 'Lines', 'Payouts', 'Tickets', 'Payout Totals']; 
  domainList: Organisation[] = [];
  selectedDomainID: number | undefined;
;

  overTimeForm = new FormGroup({
    timeFormat: new FormControl<number | null>(null, Validators.required),
    domains: new FormControl<number | null>(this._napi.userProfile.domainID, Validators.required),
    dataFeed: new FormControl<string | null>(null, Validators.required),
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  zReadForm = new FormGroup({
    posID: new FormControl('', Validators.required),
/*    timeFormat: new FormControl<number | null>(null, Validators.required),*/
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  claimsForm = new FormGroup({
    timeFormat: new FormControl<number | null>(null, Validators.required),
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  @ViewChild('drawer') menu!: MatDrawer;

  constructor(private _rapi: ReportsService, private _napi: NavService, private _api: ApiService) { }

  ngOnInit(): void {
  }

  onReportChange(x: string) {
    this.showReportWidget = false;
    if (this.selectedReportName !== x) {
      this.isLoadingReportControls = true;
      this.overTimeForm.reset();
      this.zReadForm.reset();
      this.claimsForm.reset();
      this.lineGraphData = [];
      this.report = undefined;
      switch (x) { // Used to select which report to view.
        case 'Z-Read': {
          this.reportControlsLoaded('Z-Read');
          break;
        }
        case 'Claims': {
          this.reportControlsLoaded('Claims');
          break;
        }
        case 'Over Time': {

          //if (this._napi.userProfile.claims) { // TODO limit report controls by role/claims

            if (this.domainList.length < 1) {
              this._api.getOrganisations().subscribe({
                next: (response) => {
                  this.domainList = response.items;
                  this.reportControlsLoaded('Over Time');
                },
                error: (error) => {
                  console.log('ERROR', error)
                }
              })
            } else {
              this.reportControlsLoaded('Over Time');
            }
          //} else {
          //  this.reportControlsLoaded('Over Time');
          //}
          break;
        }
        default: {
          this.showReportWidget = false;
          this.reportControlsLoaded();
          break;
        }
      }
    }
  }

  onSubmitoverTimeForm() {
    this.report = undefined;
    this.isLoadingReport = true;
    this.lineGraphData = [];
    this.selectedDomainID = this.overTimeForm.controls.domains.value || this._napi.userProfile.domainID;
    let domainData: any[] = [];
    let startDate = this.overTimeForm.controls.start.value?.toISOString();
    let reportStartDate = this.overTimeForm.controls.start.value;
    let endDate = this.overTimeForm.controls.end.value?.toISOString();
    let format = this.overTimeForm.controls.timeFormat.value || 3;
    let dataFeed = this.overTimeForm.controls.dataFeed.value;


    this._rapi.getDomainSalesOverTime(this.selectedDomainID, startDate, endDate, format, null).subscribe({
      next: (response: any[]) => {
        console.log(reportStartDate);
        console.log(response);
        reportStartDate = new Date(response[0].from)
        console.log(reportStartDate);
        //Split report into sections for use with line-graph.component setting data according to datafeed
        switch (dataFeed) {
          case 'Claims': {
            response.forEach(item => {
              domainData.push(item.claims);
            });
            break;
          }
          case 'Income': {
            response.forEach(item => {
              //Adjust currency. 
              domainData.push(item.income / 100);
            });
            break;
          }
          case 'Payouts': {
            response.forEach(item => {
              domainData.push(item.payouts);
            });
            break;
          }
          case 'Tickets': {
            response.forEach(item => {
              domainData.push(item.tickets);
            });
            break;
          }
          case 'Payout Totals': {
            response.forEach(item => {
              //Adjust currency.
              domainData.push(item.payoutTotal / 100);
            });
            break;
          }
          default: {
            dataFeed = 'Lines'
            response.forEach(item => {
              domainData.push(item.lines);
            });
            break;
          }
        } 

        this.lineGraphTitle = dataFeed;
        this.lineGraphDateFormat = {
          start: reportStartDate,
          format: this.graphTimeFormats.find(x => x.id == format)?.name
        };
        this.lineGraphData.push({
          name: this.domainList.find(x => x.organisationID == this.selectedDomainID)?.name,
          data: domainData
        });
        this.report = response;
        this.isLoadingReport = false;
        
      },
      error: (error) => {
        console.log('ERROR', error)
        this.isLoadingReport = false;
      }
    });
  }

  onSubmitZReadForm() {

    let startDate = this.zReadForm.controls.start.value?.toDateString();
    let endDate = this.zReadForm.controls.end.value?.toDateString();
    let posID = this.zReadForm.controls.posID.value;

    this._rapi.getPoSZRead(posID, startDate, endDate).subscribe({
      next: (response) => {
        this.report = response;
      },
      error: (error) => {
        console.log('ERROR', error)

      }
    });

  }

  setUpZReadReport() {

  }

  reportControlsLoaded(reportName?: string) {
    if (this.selectedReportName !== reportName) {
      this.selectedReportName = reportName;
    }
    this.showReportWidget = true;
    this.isLoadingReportControls = false;
  }
  toggleMenu() {
    this.menu.toggle();
  }
  
}
