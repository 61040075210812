<div class="widget--grid">
  <div class="widget--item">
    <div [hidden]="submitted">
      <div class="widget--header">
        <h1>Add new draw pattern</h1>
      </div>
      <div class="widget--body">

        <form [formGroup]="drawForm" (ngSubmit)="onSubmit()">

          <mat-form-field appearance="outline">
            <mat-label>First draw:</mat-label>
            <input matInput formControlName="firstDraw" [matDatepicker]="picker">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline" floatLabel="auto">
            <mat-label>Time</mat-label>
            <input type="time" matInput formControlName="time">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Repeat for x weeks:</mat-label>
            <input matInput type="text" formControlName="repeatWeeks" suffix=" Weeks" mask="separator.2">
            <mat-hint> max 524w, min 1w </mat-hint>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Ticket sales from:</mat-label>
            <input matInput type="text" formControlName="ticketSalesFrom" suffix=" Hours" mask="separator.2">
            <mat-hint>max 570h, min 3h </mat-hint>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Ticket sales stop:</mat-label>
            <input matInput type="text" formControlName="ticketSalesStop" suffix=" Hours" mask="separator.2">
            <mat-hint>max 72h, min 0h </mat-hint>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Claim delay:</mat-label>
            <input matInput type="text" formControlName="claimDelayMins" suffix=" Hours" mask="separator.2">
            <mat-hint>max 72h, min 3h </mat-hint>
          </mat-form-field>


          <button mat-button type="submit" class="btn btn-success" [disabled]="!drawForm.valid">Submit</button>
          <button mat-button type="button" class="btn btn-default" (click)="drawForm.reset()">Reset</button>
          <!--<button mat-button type="button" class="btn btn-default" (click)="debug()">debug</button>-->
        </form>
      </div>
    </div>

    <!--<div [hidden]="!submitted">
      <div class="widget--header">
        <h2>You submitted the following:</h2>
      </div>
      <div class="widget--body">
        <div class="row">
          <div class="col-xs-3">Name</div>
          <div class="col-xs-9">{{ org.name }}</div>
        </div>
        <div class="row">
          <div class="col-xs-3">Phone Number</div>
          <div class="col-xs-9">{{ org.phoneNumber }}</div>
        </div>
        <div class="row">
          <div class="col-xs-3">Address line 1</div>
          <div class="col-xs-9">{{ org.address.line1 }}</div>
        </div>
        <br>
        <button type="button" class="btn btn-primary" (click)="submitted=false">Edit</button>
      </div>
    </div>-->
  </div>
</div>
