import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { NavService } from './nav.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService {

  isLoggedIn = false;

  constructor(
    private authService: AuthService, 
    private router: Router,
    private navService: NavService
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (this.authService.isAuthenticated()) {
      let claims = this.navService.userProfile.claims
        //catch empty user claims
      if (claims == null || undefined) {
        claims = "";
      }

      switch (route.routeConfig?.path) {
        case "games": {
          if (claims.includes("gamepub") || claims.includes("game") || claims.includes("drawentry") || claims.includes("rolemaster")) {
            return true
          }
          this.router.navigate(['/dashboard']);
          return false;
        }
        case "tickets": {
          if (claims.includes("claim") || claims.includes("rolemaster")) {
            return true
          }
          this.router.navigate(['/dashboard']);
          return false;
        }
        case "users": {
          if (claims.includes("onboard") || claims.includes("rolemaster")) {
            return true
          }
          this.router.navigate(['/dashboard']);
          return false;
        }
        default: {
          return true;
        }
      }

    } else {
      this.router.navigate(['/login']);
    }
    return false;  
  }
}
