import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(
    private _auth: AuthService,
    private _router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.warn('test');
    const _token = this._auth.getToken()?.token;
    if (_token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${_token}`
        }
      });
      // console.warn('req',request, next);

    }

    // return next.handle(request);

    return next.handle(request).pipe(tap(() => { },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return;
          }
          this._router.navigate(['login']);
        }
      }));

  }
}
