import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import * as list from 'postcss/lib/list';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  navItems: any[] = [];

  constructor(
    private _router: Router,
    private _auth: AuthService,
    private _nav: NavService
  ) { }

  ngOnInit(): void {
    
    this.navItems.push({
      name: 'Dashboard',
      icon: 'home',
      url: 'dashboard',
    });

    if (this._nav.userProfile.claims !== undefined || null) {
      if (this._nav.userProfile.claims.includes('game') || this._nav.userProfile.claims.includes('gamepub') || this._nav.userProfile.claims.includes('drawentry') || this._nav.userProfile.role == 'rolemaster') {
        this.navItems.push({
            name: 'Games',
            icon: 'apps',
            url: 'games',
        });
      };
      if (this._nav.userProfile.claims.includes('claim')) {
        this.navItems.push({
            name: 'Tickets',
            icon: 'note',
            url: 'tickets',
        });
      };
      if (this._nav.userProfile.claims.includes('onboard')) {
        this.navItems.push({
          name: 'Users',
          icon: 'person',
          url: 'users',
        });
        }
    }
    //if (this._nav.userProfile.claims.includes('accounts')) {
    //  this.navItems.push();
    //}
    this.navItems.push({
      name: 'Reports',
      icon: 'file_open',
      url: 'reports',
    },
    {
      name: 'Logout',
      icon: 'exit_to_app',
      url: 'logout',
    })
  }

  navigateTo(url: string) {
    if (url === 'logout') {
      this._auth.logout();
      this._router.navigate(['login']);
    }
    else {
      this._router.navigate([url]);
    }
  }

}
