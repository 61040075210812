import { Component, Input } from '@angular/core';
import { DrawResult, GameDrawDate, GameSetup, PrizeTier } from '../../models/games.model';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-prize-breakdown',
  templateUrl: './prize-breakdown.component.html',
  styleUrls: ['./prize-breakdown.component.scss']
})
export class PrizeBreakdownComponent {

  constructor() { }

  @Input() game!: GameSetup;
  @Input() draw?: GameDrawDate
  @Input() drawResult?: DrawResult;

  dataSource: any;
  displayedColumns: string[] = ['prizeLevel', 'estPrizePot', 'estimatedWinners', 'estimatedPrize'];
  historicMode = false;
  currency = environment.currency;
  totalWinners = 0;

  ngOnInit(): void {
    this.setUpTable();
  }

  ngOnChanges() {
    this.dataSource = null;
    this.displayedColumns = ['prizeLevel', 'estPrizePot', 'estimatedWinners', 'estimatedPrize'];
    this.setUpTable();
  }

  setUpTable() {
    if (this.drawResult) {
      this.displayedColumns = ['prizeLevel', 'prizePot', 'prize', 'wins'/*, 'claimedCash', 'claimed%'*/];
      this.historicMode = true;
      this.dataSource = this.drawResult.prizeTiers;
    } else {
      this.dataSource = this.game.prizeTiers;
    }
  }

  estimatePrize(element: PrizeTier) {
    if (this.draw) {
      let probability = this.round((this.draw.linesSold / this.round(1 / element.probability)));
      if (probability == 0) {
        return 0
      } else {
          return this.estimatePrizePot(this.draw.linesSold, element.prizeFundPercentage) / probability;
      }
    } else {
      return
    }
  }

  estimatePrizePot(linesSold: number, prizeFundPercentage: number) {
      let estPrizePot = ((((linesSold * (this.game.pricePerTicket / 100)) / 100) * this.game.prizeFundPercentage) / 100) * prizeFundPercentage;
      return estPrizePot
  }

  round(x: number) {
    return Math.round(x);
  }

  formatWinNum(index: number) {
    let toSkip = 0;
    for (let i = 0; i < index; i++) {
      toSkip = toSkip + this.game.ballTypes[i].numberOfBalls;
    }
    let formedNums = this.drawResult?.numbers.slice(toSkip, toSkip + this.game.ballTypes[index].numberOfBalls);
    
    return formedNums;
  }

}
