import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TicketClaimInfo, TicketPrizeBreakdown } from '../../models/games.model';
import { TicketingApiService } from '../../services/ticketing-api.service';
import { environment } from 'environments/environment';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent {

  constructor(private formBuilder: FormBuilder, private api: TicketingApiService) { }


  ticketForm = this.formBuilder.group({
     ticketID: new FormControl('', [Validators.required])
  });

  selectedTicketDetails: TicketPrizeBreakdown | undefined;
  errorMSG: string | undefined;
  isLoading: boolean = false;
  isLoadingTicketUpdate: boolean = false;
  currency = environment.currency;
  @ViewChild('drawer') menu!: MatDrawer;

  ngOnInit(): void {

  }

  toggleMenu() {
    this.menu.toggle();
  }

  onSubmit() {
    let ticketID = this.ticketForm.controls.ticketID.getRawValue();
    if (ticketID !== null) {
      this.isLoading = true;
      this.selectedTicketDetails = undefined;
      this.api.getTicketPrize(ticketID).subscribe({
        next: (response: TicketPrizeBreakdown) => {
          this.errorMSG = undefined;
          this.selectedTicketDetails = response;
          this.isLoading = false;
        },
        error: (err) => {
          
          this.errorMSG = err.message;
          console.log('ERROR', err);
          this.isLoading = false;
        }
      });
    }
  }

  addClaim() {
    if (this.selectedTicketDetails?.ticketID) {
      this.isLoadingTicketUpdate = true;
      this.api.postClaim(this.selectedTicketDetails?.ticketID).subscribe({
        next: (response: TicketClaimInfo) => {
          if (this.selectedTicketDetails) {
            this.selectedTicketDetails.claimInfo = response;
          }
          this.isLoadingTicketUpdate = false;
        },
        error: (err) => {
          console.log('ERROR', err);
          this.errorMSG = err.error.message;
          this.isLoadingTicketUpdate = false;
        }
      })
    }
  }

  removeClaim() {
    if (this.selectedTicketDetails?.ticketID) {
      this.isLoadingTicketUpdate = true;
      this.api.removeClaim(this.selectedTicketDetails?.ticketID).subscribe({
        next: (response: TicketClaimInfo) => {
          if (this.selectedTicketDetails) {
            this.selectedTicketDetails.claimInfo = response;
          }
          this.isLoadingTicketUpdate = false;
        },
        error: (err) => {
          console.log('ERROR', err);
          this.errorMSG = err.error.message;
          this.isLoadingTicketUpdate = false;
        }
      })
    }
  }

  markAsPaid() {
    if (this.selectedTicketDetails?.ticketID && this.selectedTicketDetails?.claimInfo?.registeredBy) {
      this.isLoadingTicketUpdate = true;
      this.api.markTicketPaid(this.selectedTicketDetails?.ticketID).subscribe({
        next: (response: TicketClaimInfo) => {
          if (this.selectedTicketDetails) {
            this.selectedTicketDetails.claimInfo = response;
          }
          this.isLoadingTicketUpdate = false;
        },
        error: (err) => {
          console.log('ERROR', err);
          this.errorMSG = err.error.message;
          this.isLoadingTicketUpdate = false;
        }
      })
    }
  }
}
