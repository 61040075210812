<div class="login--wrapper">
    <div class="login-form--wrapper">
        <div class="logo">
            <img src="../../../assets/icon/Lottery logo LOGIN.svg"> 
        </div>
        <form class="form" [formGroup]="loginForm" (ngSubmit)="login()">
            
            <mat-form-field appearance="fill">
                <mat-label>Username (tel or email)</mat-label>
                <input matInput formControlName="username">
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Password</mat-label>
                <input matInput formControlName="password" type="password">
            </mat-form-field>

            <button mat-raised-button type="submit" color="primary" (click)="login()">Login</button>
        </form>
        <div>v{{currentApplicationVersion}}</div>
    </div>
    
</div>
