import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrganisationAddOptions } from '../../models/onboarding.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';


@Component({
  selector: 'app-organisation-form',
  templateUrl: './organisation-form.component.html',
  styleUrls: ['./organisation-form.component.scss']
})
export class OrganisationFormComponent implements OnInit {



  constructor(private api: ApiService) { }

  @Input() org: OrganisationAddOptions = {} as OrganisationAddOptions;
  @Output() refreshOrgs = new EventEmitter<any>();

  organisationForm = new FormGroup({
    name: new FormControl(this.org.name, [Validators.required, Validators.minLength(1), Validators.maxLength(36)]),
    //email: new FormControl('', [Validators.maxLength(255), Validators.email]),
    //phone: new FormControl('', [Validators.maxLength(20), Validators.pattern('[- +()0-9]+')]),
    address: new FormControl('', [Validators.maxLength(128), Validators.minLength(1), Validators.required]),
    //city: new FormControl('', [Validators.maxLength(36), Validators.minLength(1), Validators.required]),
    //postcode: new FormControl('', [Validators.maxLength(10), Validators.minLength(1), Validators.required]),
    authRef: new FormControl('', [Validators.maxLength(8), Validators.pattern('^[-a-zA-Z0-9]{0,8}$')]),
  });

  
  submitted = false;

  onSubmit() {
    this.org.authProviderRef
    if (this.organisationForm.valid) {
      let data = this.organisationForm.getRawValue();
      this.submitted = true;
      this.api.postOrganisation(data).subscribe({
        next: (response) => {
          console.log('SUCCESS', response)
          this.refreshOrgs.emit(data.name);
        },
        error: (error) => {
          console.log('ERROR', error)
        }
      });
    }

  }

  ngOnInit(): void {
    //if (!this.org.address) {
    //  this.org.address = {} as AddressAddOptions;
    //}
  }


}
