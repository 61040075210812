import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavService } from 'app/services/nav.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input() title: any;
  @Output() toggleMenuOutput = new EventEmitter();
  initials = '';
  constructor(
    private _nav: NavService
  ) { }

  ngOnInit(): void {
    this.initials = this._nav.userProfile.initials;
  }

  toggleMenu() {
    this.toggleMenuOutput.emit();
  }

}
