<mat-drawer-container class="page--wrapper">
  <mat-drawer #drawer (opened)="true" class="column column--left">

    <app-sidebar></app-sidebar>
  </mat-drawer>

  <div class="column">
          <app-navbar title="Games" (toggleMenuOutput)="toggleMenu()"></app-navbar>
    <app-mat-spinner-overlay *ngIf="isLoading" [overlay]="true">
    </app-mat-spinner-overlay>
    <div class="main--content" *ngIf="!isLoading">

      <!--total games: {{totalGames}} index: {{gameIndex}}-->

      <div class="widget--grid games">
        <div class="widget--item span--5">
          <div class="widget--header">
            <div class="title">
              <h1>
                Games
                <button mat-fab color="primary" matTooltip="Add new game" style="float:right" (click)="viewGameDetails(undefined)">
                  <mat-icon>library_add</mat-icon>
                </button>
              </h1>
            </div>
          </div>
        </div>

        <div *ngFor="let item of games.items | slice:gameIndex:gameIndex + 4; index as i">
          <div class="widget--item">

            <div class="widget--header">

              <div *ngIf="item.isPublished; else draft_game_icon">

                <h2>

                  <!--<button mat-fab class="isLive">
                  <mat-icon>live_tv</mat-icon>
                </button>-->
                  <mat-icon fontIcon="live_tv"></mat-icon>
                  LIVE
                </h2>
              </div>
              <ng-template #draft_game_icon>

                <h2>
                  <!--<button mat-fab color="warn">
                  <mat-icon>tv_off</mat-icon>
                </button>-->
                  <mat-icon fontIcon="tv_off"></mat-icon>
                  DRAFT
                </h2>
              </ng-template>
              <div class="title">
                {{item.title}}
              </div>
            </div>
            <div class="widget--body">


              <div class="details--list">
                <div class="item">
                  <div class="title">Price per Ticket</div>
                  <div class="amount">{{currency}} {{item.pricePerTicket / 100 | number: '.2'}}</div>
                </div>
                <div class="item">
                  <div class="title">

                    Prize fund percentage

                  </div>
                  <div class="amount">{{item.prizeFundPercentage}}%</div>
                </div>
                <div class="item">
                  <div class="title">Created: </div>
                  <div class="date">{{item.created | date:'fullDate'}}</div>
                </div>
              </div>
              <div class="item">
                <div class="title">Description: </div>
                <div><span class="description">{{item.description}}</span></div>
              </div>
              <div class="item">
                <button mat-raised-button color="primary" (click)="viewGameDraws(item)"> View draws</button>

                <button mat-raised-button color="primary" type="button" class="btn btn-default" (click)="viewGameDetails(item)">View details</button>

                <button *ngIf="item.isPublished && claims.includes('drawentry')" mat-raised-button color="primary" type="button" class="btn btn-default" (click)="viewDrawsAwaitingResults(item)">Enter WIN</button>

                <button *ngIf="!item.isPublished && claims.includes('game')" mat-raised-button color="warn" type="button" class="btn btn-default" (click)="removeDraftGame(item.gameID)">Delete draft</button>

              </div>
            </div>
            <span *ngIf="i == 0" id="prevButton">
              <button *ngIf="gameIndex > 0" mat-fab color="primary" aria-label="Show previous games." (click)="showPrevGames()">
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
            </span>
            <span *ngIf="i == 3" id="nextButton">
              <button *ngIf="(gameIndex + 4) < totalGames" mat-fab color="primary" aria-label="Show next games." (click)="showNextGames()">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </span>
          </div>
        </div>

        <div class="widget--item span--5">

        </div>





        <!--<div class="widget--item span--5">
        <div class="widget--header">
          <div class="title">
            <h1>Game Templates</h1>
          </div>
        </div>
      </div>
      <div class="widget--item" *ngFor="let item of games.items">
        <div class="widget--header">
          <div class="title">
            {{item.title}}
          </div>
        </div>
        <div class="widget--body">
          <div class="details--list">
            <div class="item">
              <div class="title">Published:  </div>
              <div class="date">{{item.isPublished}}</div>
            </div>
            <div class="item">
              <div class="title">Created: </div>
              <div class="date">{{item.created | date:'fullDate'}}</div>
            </div>
            <div class="item">
              <div class="title">Price per line</div>
              <div class="amount">{{currency}} {{item.pricePerTicket / 100 | number: '.2'}}</div>
            </div>
            <div class="item">
              <div class="title">Prize fund percentage</div>
              <div class="amount">{{item.prizeFundPercentage}}%</div>
            </div>
          </div>
          <div class="item">
            <div class="title">Description: </div>
            {{item.description}}-->
        <!--<button mat-raised-button color="primary" (cllick)="viewDraws(item.gameID)"> View draws</button>-->
        <!--</div>
        </div>
      </div>-->



        <div class="widget--item span--5">
          <div style="display: flex; justify-content: center;">
            <app-mat-spinner-overlay *ngIf="isLoadingGameInfo" [overlay]="false">
            </app-mat-spinner-overlay>
          </div>
        </div>

        <div [hidden]="!showDrawSection" class="widget--item span--5 calendar">
          <div class="widget--header">
            <div class="title">
              <h1>{{selectedGameItem?.title}} Draws</h1>
              <button *ngIf="!selectedGameItem?.isPublished && claims.includes('gamepub')" mat-raised-button color="warn" [disabled]="checkGameCanBePublished()" type="button" class="btn btn-default" style="float:right" (click)="publishGame()">Publish Game</button>
            </div>
          </div>
          <div class="widget--body">
            <mat-calendar id="drawCalendar" #drawCalendar [(selected)]="draws" [dateClass]="highlightDrawDate()"></mat-calendar>
            <div *ngIf="claims.includes('gamepub')">
              <button *ngIf="!showDrawForm" mat-raised-button color="primary" type="button" class="btn btn-default" (click)="addNewDraws()" style="float:right">Add draws</button>
              <button *ngIf="showDrawForm" mat-button type="button" class="btn btn-default" (click)="addNewDraws()" style="float:right">Cancel</button>
            </div>
            <div *ngIf="showDrawForm && selectedGameItem">
              <app-draw-form [game]="selectedGameItem" (refreshGames)="gameDrawAdded($event)">></app-draw-form>
            </div>
          </div>
        </div>

        <div *ngIf="showDrawAwaitingSection" class="widget--item span--5 calendar">
          <div class="widget--header">
            <div class="title">
              <h1>Draws awaiting winning input</h1>
              <h2>{{selectedGameItem?.title}}</h2>
            </div>
          </div>
          <div class="widget--body">
            <div *ngIf="winFormId !== undefined">
              <app-winning-number-form *ngIf="selectedGameItem" [Game]="selectedGameItem" [DrawID]="winFormId" (refreshGames)="winningNumbersPublished($event)"></app-winning-number-form>
            </div>
            <!--<mat-list>
            <mat-list-item *ngFor="let draw of drawsAwaitingResult.items; index as i">
              <div matListItemTitle>Draw date: {{draw.drawDate | date}}</div>
              <div matListItemLine>
                <button *ngIf="i == 0" mat-raised-button color="primary" (click)="setWinFormId(draw.drawID)">Enter winning numbers</button>
              </div>
            </mat-list-item>
          </mat-list>-->

            <div *ngIf="drawsAwaitingResult.items.length > 0; else noDrawBlock">
              <div *ngIf="winFormId == undefined">
                <h3>{{drawsAwaitingResult.items[0].drawDate | date}}'s draw is awaiting results!</h3>
                <button mat-raised-button color="primary" (click)="setWinFormId(drawsAwaitingResult.items[0].drawID)">Enter winning numbers</button>

              </div>
            </div>
            <ng-template #noDrawBlock>
              <h3>No draws awaiting input</h3>
            </ng-template>
          </div>
        </div>


        <div *ngIf="showDetailsSection" class="widget--item span--5">
          <div class="widget--header">
            <div class="title">
              <h1 *ngIf="selectedGame;else new_game_title">{{selectedGame.title}} Details</h1>
              <ng-template #new_game_title><h1>New game Details</h1></ng-template>
            </div>
          </div>
          <div class="widget--body">
            <app-game-form [game]="selectedGame" (refreshGames)="draftGameAdded($event)"></app-game-form>
            <div *ngIf="selectedGame">
              <app-prize-tier-selector [game]="selectedGame"></app-prize-tier-selector>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-drawer-container>
