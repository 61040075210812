import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { Page, GameListItem, GameSetup, DrawResult, DrawResultAddOptions, DrawAddOptions, CurrentGame, GameDrawDate } from '../models/games.model';
import { Organisation, OrganisationAddOptions, OrgManAddOptions, OrgUserAddOptions, PointOfSale, PointOfSaleAddOptions, Region, UserAddOptions, UserInfo } from '../models/onboarding.model';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _apiBaseUrl = environment.baseUrl;
  
  constructor(
    private _http: HttpClient,
  ) { }

  public login(username: string, domain: string, password: string): Observable<Object> {
    const _authtoken = btoa(btoa(username) + '@'+ btoa(domain) + ':' + btoa(password));
    return this._http.post(
      `${this._apiBaseUrl}/zero/authenticate?authtoken=${_authtoken}&lifetime=3600`, null
    ); 
  }
  // *******************
  // GAMES SECTION
  // *******************
  public getGames(limit?: number, offset?: number): Observable<Page<GameListItem>> {
    return this._http.get<Page<GameListItem>>(
      `${this._apiBaseUrl}/fortuna/game`
    );
  }

  public getCurrentGame(limit?: number, offset?: number): Observable<Page<CurrentGame>> {
    return this._http.get<Page<CurrentGame>>(
      `${this._apiBaseUrl}/fortuna/current-game`
    );
  }

  public getDraw(id: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseUrl}/fortuna/draw/${id}/game`
    );
  }

  public metaData(): Observable<any> {
    return this._http.get(
      `${this._apiBaseUrl}/fortuna/metadata`
    );
  }

  public getGameDraws(id: number, start?: Date | Moment, end?: Date | Moment): Observable<Page<GameDrawDate>> {
    if (start && end) {
      return this._http.get<Page<GameDrawDate>>(
        `${this._apiBaseUrl}/fortuna/game/${id}/draw?from=${start.toISOString()}&to=${end.toISOString()}`
      );
    } else {
      let today = new Date();
      let aMonthsTime = moment(today);
      let aMonthsBeforeTime = moment(today);
      aMonthsTime.add(1, 'months');
      aMonthsBeforeTime.subtract(1, "months");
      return this._http.get<Page<GameDrawDate>>(
        `${this._apiBaseUrl}/fortuna/game/${id}/draw?from=${today.toISOString()}&to=${aMonthsTime.toISOString()}`
      );
    }

  }

  public postGameDrawsPattern(id: number, drawPattern: any): Observable<any> {
    return this._http.post(
      `${this._apiBaseUrl}/fortuna/game/${id}/draw`, drawPattern
    );
  }

  public getGameDrawsAwaitingResult(id: number): Observable<any> {
    return this._http.get(
      `${this._apiBaseUrl}/fortuna/game/${id}/draw/awaiting-result`
    );
  }

  public getDrawResult(id: number): Observable<DrawResult> {
    return this._http.get<DrawResult>(
      `${this._apiBaseUrl}/fortuna/draw/${id}/result`
    );
  }

  public getAllDrawResults(id: number): Observable<DrawResult> {
    return this._http.get<DrawResult>(
      `${this._apiBaseUrl}/fortuna/game/${id}/drawresults`
    );
  }

  public postDrawResult(id: number, results: DrawResultAddOptions): Observable<DrawResult> {
    return this._http.post<DrawResult>(
      `${this._apiBaseUrl}/fortuna/draw/${id}/result`, results
    );
  }

  public removeDrawResult(id: number): Observable<any> {
    return this._http.delete(
      `${this._apiBaseUrl}/fortuna/draw/${id}/result`
    )
  }

  // public getGames(): Observable<any> {
  //   return this._http.get(
  //     `${this._apiBaseUrl}/games`
  //   );
  // }

  public getGame(id: number): Observable<GameSetup> {
    return this._http.get<GameSetup>(
      `${this._apiBaseUrl}/fortuna/game/${id}`
    );
  }


  public addGameDraft(game: any): Observable<GameSetup> {
    return this._http.post<GameSetup>(
      `${this._apiBaseUrl}/fortuna/game`, game
    );
  }

  public updateGameDraft(game: any): Observable<GameSetup> {
    return this._http.put<GameSetup>(
      `${this._apiBaseUrl}/fortuna/game`, game
    );
  }

  public removeDraftGame(id: number): Observable<any> {
    return this._http.delete(
      `${this._apiBaseUrl}/fortuna/game/${id}`
    )
  }

  public addPrizeTier(gameID: number, prizeTier: any): Observable<any>  {
    return this._http.post(
      `${this._apiBaseUrl}/fortuna/game/${gameID}/prize-tier`, prizeTier
    )
  }

  public updatePrizeTier(prizeTier: any, prizeTierID: number): Observable<any> {
    return this._http.put(
      `${this._apiBaseUrl}/fortuna/prize-tier/${prizeTierID}`, prizeTier
    )
  }

  public deletePrizeTier(prizeTierID: number): Observable<any> {
    return this._http.delete(
      `${this._apiBaseUrl}/fortuna/prize-tier/${prizeTierID}`
    )
  }


  public publishGame(id: number): Observable<any> {
    return this._http.post(
      `${this._apiBaseUrl}/fortuna/game/${id}/publish`, null
    )
  }


  // *******************
  // USERS SECTION
  // *******************


  // Organisations

  public getOrganisation(id: number): Observable<any> {
    return this._http.get<Organisation>(
      `${this._apiBaseUrl}/onboard/organisation/${id}`
    );
  }

  public postOrganisation(org: any): Observable<any> {
    return this._http.post(
      `${this._apiBaseUrl}/onboard/admin/organisation`, org
    );
  }

  public getOrganisations(): Observable<any> {
    return this._http.get<Organisation[]>(
      `${this._apiBaseUrl}/onboard/admin/organisation`
    );
  }

  public getPoSByOrg(id: number, limit?: number, offset?: number): Observable<any> {
    return this._http.get<Page<PointOfSale>>(
      `${this._apiBaseUrl}/onboard/admin/organisation/${id}/pos`
    );
  }

  public postPoS(pos: PointOfSaleAddOptions): Observable<any> {
    return this._http.post(
      `${this._apiBaseUrl}/onboard/api/pos`, pos
    );
  }
  //ADMIN

  public getOrganisationUsersAdmin(id: number, limit?: number, offset?: number): Observable<any> {
    return this._http.get<Page<UserInfo>>(
      `${this._apiBaseUrl}/onboard/admin/organisation/${id}/user`
    );
  }

  public postOrganisationManager(id: number, user: OrgManAddOptions): Observable<any> {
    return this._http.post(
      `${this._apiBaseUrl}/onboard/admin/organisation/${id}/manager`, user
    );
  }

  public postOrganisationUser(id: number, user: OrgUserAddOptions): Observable<any> {
    return this._http.post(
      `${this._apiBaseUrl}/onboard/admin/organisation/${id}/user`, user
    );
  }

  public getRegions(id: number): Observable<Page<Region>> {
    return this._http.get<Page<Region>>(
      `${this._apiBaseUrl}/onboard/api/organisation/${id}/region`
    );
  }

  public getUserAdmin( userID: number): Observable<UserInfo> {
    return this._http.get<UserInfo>(
      `${this._apiBaseUrl}/onboard/admin/user/${userID}`
    );
  }

  //Roles

  public getRoles(): Observable<any> {
    return this._http.get<string[]>(
      `${this._apiBaseUrl}/onboard/admin/role`
    );
  }

  public addRole(rolename: string, userID: number): Observable<any> {
    return this._http.put<string[]>(
      `${this._apiBaseUrl}/onboard/admin/role/${rolename}/user/${userID}`, rolename
    );
  }

  public removeRole(rolename: string, userID: number): Observable<any> {
    return this._http.delete<string[]>(
      `${this._apiBaseUrl}/onboard/admin/role/${rolename}/user/${userID}`
    );
  }
}
