<table *ngIf="!historicMode && draw" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->
  <!-- Position Column -->
  <tr>
    <ng-container matColumnDef="prizeLevel">
      <th mat-header-cell *matHeaderCellDef> Prize Level </th>
      <td mat-cell *matCellDef="let element; index as i">
        <ng-container *ngFor="let req of element.requirements; index as j; first as isFirst; odd as isOdd; even as isEven; last as isLast">

          <ng-container *ngIf="!isFirst && !isLast; else standardBalls">
            <div *ngIf="isOdd" class="odd Ball">
              {{req.matches}}
            </div>
            <div *ngIf="isEven" class="even Ball">
              {{req.matches}}
            </div>
          </ng-container>
          <ng-template #standardBalls>
            <div *ngIf="isFirst" class="first Ball">
              {{req.matches}}
            </div>
            <div *ngIf="isLast" class="last Ball">
              {{req.matches}}
            </div>
          </ng-template>
        </ng-container>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="estPrizePot">
      <th mat-header-cell *matHeaderCellDef> Est. Prize Pot </th>
      <!--draw.jackpot ==> linessold * (price / 100)-->
      <!--  if i = 1 show jackpot estimate-->
      <td mat-cell *matCellDef="let element">{{currency}}{{estimatePrizePot(draw.linesSold, element.prizeFundPercentage) | number:'1.0-0'}}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="estimatedWinners">
      <th mat-header-cell *matHeaderCellDef> Est. Winners </th>
      <td mat-cell *matCellDef="let element"> {{(draw.linesSold / round((1 / element.probability))) | number:'1.0-0'}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="estimatedPrize">
      <th mat-header-cell *matHeaderCellDef> Est. prize </th>
      <td mat-cell *matCellDef="let element">{{currency}}{{estimatePrize(element) | number:'1.0-0'}}</td>
    </ng-container>
  </tr>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<div class="historicContainer" *ngIf="historicMode && drawResult">

  <h2 *ngIf="drawResult.isProcessed && drawResult.prizeTiers.length == 0">There were no winners.</h2>
  <h2 *ngIf="!drawResult.isProcessed">Draw is processing.</h2>

  <ng-container *ngIf="drawResult.isProcessed && drawResult.prizeTiers.length > 0">

    <ng-template #moreInfo>
      <div class="item">
        <div class="title">Total Tickets</div>
        <div class="amount">{{drawResult.totalTickets}}</div>
      </div>
      <div class="item">
        <div class="title">Total Lines</div>
        <div class="amount">{{drawResult.totalLines}}</div>
      </div>
      <div class="item">
        <div class="title">Income</div>
        <div class="amount">{{currency}} {{drawResult.income / 100 | number:'1.0-1'}}</div>
      </div>
      <div class="item">
        <div class="title">Total prize fund</div>
        <div class="amount">{{currency}} {{drawResult.totalPrizeFund / 100 | number:'1.0-1'}}</div>
      </div>
      <div class="item">
        <div class="title">Excess</div>
        <div class="amount">{{currency}} {{drawResult.excess / 100 | number:'1.0-1'}}</div>
      </div>
      <div class="item">
        <div class="title">Charity</div>
        <div class="amount">{{currency}} {{drawResult.charity / 100 | number:'1.0-1'}}</div>
      </div>
      <div class="item">
        <div class="title">Total wins</div>
        <div class="amount">{{totalWinners}}</div>
      </div>
      <div class="item">
        <div class="title">Rollover position</div>
        <div class="amount">{{drawResult.prizeTiers[0].currentRolloverSequenceIndex}}/{{drawResult.prizeTiers[0].rolloverDraws}}</div>
      </div>
      <div class="item">
        <div class="title">Rollover amount</div>
        <div class="amount">{{currency}} {{drawResult.prizeTiers[0].currentRolloverAmount / 100 | number:'1.0-1'}}</div>
      </div>
      <div>
        <div class="title">Winning numbers</div>
        <div class="winningNumbersDisplay">
          <div *ngFor="let ballT of game.ballTypes; index as i; first as isFirst; odd as isOdd; even as isEven; last as isLast">
            <div *ngFor="let number of formatWinNum(i); index as j">
              <ng-container *ngIf="!isFirst && !isLast; else standardBalls">
                <div *ngIf="isOdd" class="odd Ball">
                  {{number}}
                </div>
                <div *ngIf="isEven" class="even Ball">
                  {{number}}
                </div>
              </ng-container>
              <ng-template #standardBalls>
                <div *ngIf="isFirst" class="first Ball">
                  {{number}}
                </div>
                <div *ngIf="isLast && !isFirst" class="last Ball">
                  {{number}}
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <!--- Note that these columns can be defined in any order.
    The actual rendered columns are set as a property on the row definition" -->
      <!-- Position Column -->
      <tr>
        <ng-container matColumnDef="prizeLevel">
          <th mat-header-cell *matHeaderCellDef> Prize Level</th>
          <td mat-cell *matCellDef="let element; index as i">
            <ng-container *ngFor="let req of element.requirements; index as j; first as isFirst; odd as isOdd; even as isEven; last as isLast">

              <ng-container *ngIf="!isFirst && !isLast; else standardBalls">
                <div *ngIf="isOdd" class="odd Ball">
                  {{req.matches}}
                </div>
                <div *ngIf="isEven" class="even Ball">
                  {{req.matches}}
                </div>
              </ng-container>
              <ng-template #standardBalls>
                <div *ngIf="isFirst" class="first Ball">
                  {{req.matches}}
                </div>
                <div *ngIf="isLast && !isFirst" class="last Ball">
                  {{req.matches}}
                </div>
              </ng-template>
            </ng-container>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="prizePot">
          <th mat-header-cell *matHeaderCellDef> Prize Pot </th>
          <td mat-cell *matCellDef="let element">{{currency}}{{((element.prizePerTicket / 100) * element.winningTickets) | number:'1.0-1' }} </td>
        </ng-container>

        <ng-container matColumnDef="prize">
          <th mat-header-cell *matHeaderCellDef> Prize </th>
          <td mat-cell *matCellDef="let element">{{currency}}{{element.prizePerTicket / 100 | number:'1.0-1' }} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="wins">
          <th mat-header-cell *matHeaderCellDef> Wins </th>
          <td mat-cell *matCellDef="let element">{{element.winningTickets}}</td>
        </ng-container>

        <!-- Weight Column -->
        <!--<ng-container matColumnDef="claimedCash">
          <th mat-header-cell *matHeaderCellDef> Claimed Cash </th>
          <td mat-cell *matCellDef="let element">  </td>
        </ng-container>-->

        <!-- Symbol Column -->
        <!--<ng-container matColumnDef="claimed%">
          <th mat-header-cell *matHeaderCellDef> Claimed % </th>
          <td mat-cell *matCellDef="let element"> </td>
        </ng-container>
      </tr>-->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>


  </ng-container>



</div>

