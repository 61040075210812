import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

interface highChartsTimeFormat {
  start: Date;
  format: string;
}

@Component({
  selector: 'app-line-graph',
  templateUrl: './line-graph.component.html',
  styleUrls: ['./line-graph.component.scss']
})
export class LineGraphComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;


  @Input() yAxisName!: string;
  @Input() data!: any;
  @Input() timeData!: highChartsTimeFormat;

  line1!: Highcharts.Options;
  highChartsinterval: number = 0
  constructor() { }

  ngOnInit(): void {
    console.log(Date.UTC(this.timeData.start.getFullYear(), this.timeData.start.getMonth(), this.timeData.start.getDay(), this.timeData.start.getHours()));
    console.log(this.timeData.start.getTime());

    switch (this.timeData.format) { // Used to select which report to view.
      case 'Hour': {
        this.highChartsinterval = 3600 * 1000 //minutes * miliseconds
        break;
      }
      case 'Day': {
        this.highChartsinterval = 24 * 3600 * 1000 //hours * minutes * miliseconds
        break;
      }
      case 'Week': {
        this.highChartsinterval =7 * 24 * 3600 * 1000 //weeks * hours * minutes * miliseconds
        break;
      }
      case 'Month': {
        this.highChartsinterval = 86400000 * 30 //minutes * miliseconds
        break;
      }
      default: {

        break;
      }
    }

    Highcharts.setOptions({
      time: {
        useUTC: false
      }
    });

    this.line1 = {

      colors: ['#ee2b7f', '#cfa02a', '#35b5e9', '#97aa00', '#262a60',
        '#e30613', '#1d71b8', '#d3d3d3', '#c42525', '#a6c96a'],
      title: {
        text: '',
        align: 'left'
      },
      credits: {
        enabled: false
      },
      yAxis: {
        title: {
          text: this.yAxisName
        }
      },

      xAxis: {
        type: 'datetime'
        // accessibility: {
        //   rangeDescription: 'Range: 2010 to 2020'
        // }
      },

      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          },
          //pointStart: Date.UTC(2023, 3, 31, 6, 0, 0, 0),
          //pointInterval: 24 * 3600 * 1000
          pointStart: this.timeData.start.getTime(),
          pointInterval: this.highChartsinterval
        }
      },

      series: this.data as any,
    };
  }

}
