<mat-drawer-container class="page--wrapper">
  <mat-drawer #drawer (opened)="true" class="column column--left">
    <app-sidebar></app-sidebar>
  </mat-drawer>
  <app-navbar title="Dashboard" (toggleMenuOutput)="toggleMenu()"></app-navbar>
  <div class="column">
    <app-mat-spinner-overlay *ngIf="isLoading" [overlay]="true">
    </app-mat-spinner-overlay>
    <div class="main--content" *ngIf="currentGame !== undefined">

      <div class="widget--grid games">
        <div class="widget--item span--5">
          <div class="widget--header">
            <div class="title">
              Current Game: {{currentGame.title}} &mdash; ID: {{currentGame.gameID}}
              <span *ngIf="currentGameList.length > 1" class="nextButton">
                <button [disabled]="isLoadingDraws" mat-mini-fab color="primary" aria-label="Show next games." (click)="getNextGame()">
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
              </span>
            </div>
            <div class="date">{{currentGame.description}}</div>
          </div>
          <div class="widget--body">
            <div class="details--list">
              <div class="item">
                <div class="title">Price per line</div>
                <div class="amount">{{currency}} {{currentGame.pricePerTicket / 100 | number: '.2'}}</div>
              </div>
              <!--<div class="item">
            <div class="title">Prize fund percentage</div>
            <div class="amount">{{currentGame.prizeFundPercentage}}%</div>
          </div>-->
            </div>
          </div>
          <div class="widget--footer">
          </div>
        </div>
        <div *ngIf="isLoadingDraws" class="widget--item span--5">
          <div style="display: flex; justify-content: center;">
            <app-mat-spinner-overlay [overlay]="false">
            </app-mat-spinner-overlay>
          </div>
        </div>
        <ng-container *ngIf="!isLoadingDraws">
          <div class="widget--item" *ngFor="let draw of drawList | slice:0:4, index as i">
            <div class="widget--header">
              <div *ngIf="i === 0" class="title">Current Draw</div>
              <div *ngIf="i === 1" class="title">Next Draw</div>
              <div *ngIf="i > 1" class="title">Future Draw</div>
              <div class="date">{{draw.drawDate | date: 'dd-MM-YYYY, h:mm a'}}</div>
            </div>
            <div class="widget--body">
              <div class="details--list">
                <div class="item">
                  <div class="title">Lines Sold</div>
                  <div class="amount">{{draw.linesSold | number:'1.0-0'}}</div>
                </div>
                <div class="item">
                  <div class="title">Estimated Jackpot</div>
                  <div class="amount">{{currency}} {{draw.jackpotEstimate / 100 | number }}</div>
                </div>
                <div class="item">
                  <div class="title">Ticket sales close at</div>
                  <div class="amount">{{draw.purchaseCutOff | date: 'dd-MM-YYYY, h:mm a'}}</div>
                </div>
                <div class="item">
                  <div class="title">Claims open at</div>
                  <div class="amount">{{draw.claimFrom | date: 'dd-MM-YYYY, h:mm a'}}</div>
                </div>

              </div>
            </div>
            <div class="widget--footer">
            </div>
          </div>
        </ng-container>


        <!-- <div class="widget--item">
      <div class="widget--header">
        <div class="title">Next Draw</div>
        <div class="date">08/03/2023</div>
      </div>
      <div class="widget--body">
        <div class="details--list">
          <div class="item">
            <div class="title">Tickets Sold</div>
            <div class="amount">55,203,892</div>
          </div>
          <div class="item">
            <div class="title">Estimated Jackpot</div>
            <div class="amount">{{currency}} 10,924,445</div>
          </div>
        </div>
      </div>
      <div class="widget--footer">
      </div>
    </div>

    <div class="widget--item">
      <div class="widget--header">
        <div class="title">Next Draw</div>
        <div class="date">08/03/2023</div>
      </div>
      <div class="widget--body">
        <div class="details--list">
          <div class="item">
            <div class="title">Tickets Sold</div>
            <div class="amount">55,203,892</div>
          </div>
          <div class="item">
            <div class="title">Estimated Jackpot</div>
            <div class="amount">{{currency}} 10,924,445</div>
          </div>
        </div>
      </div>
      <div class="widget--footer">
      </div>
    </div>

    <div class="widget--item">
      <div class="widget--header">
        <div class="title">Next Draw</div>
        <div class="date">08/03/2023</div>
      </div>
      <div class="widget--body">
        <div class="details--list">
          <div class="item">
            <div class="title">Tickets Sold</div>
            <div class="amount">55,203,892</div>
          </div>
          <div class="item">
            <div class="title">Estimated Jackpot</div>
            <div class="amount">{{currency}} 10,924,445</div>
          </div>
        </div>
      </div>
      <div class="widget--footer">
      </div>
    </div> -->
      </div>
      <div *ngIf="drawResult !== undefined && gameDetails !== undefined && showExtraInfo && historicDrawList" class="widget--grid" >
        <div class="widget--item span--5">
          <div class="widget--header">
            <div class="title">
              <div class="date">{{historicDrawList[selectedHistoricDrawIndex].drawDate | date: 'dd-MM-YYYY, h:mm a'}}</div>
            </div>
            <div class="date"></div>
          </div>
          <div class="widget--body">
            <div class="details--list">
              <div class="winningNumbersDisplay">
                <div class="item">
                  <div class="title">Total Tickets</div>
                  <div class="amount">{{drawResult.totalTickets}}</div>
                </div>
                <div class="item">
                  <div class="title">Total Lines</div>
                  <div class="amount">{{drawResult.totalLines}}</div>
                </div>
                <div class="item">
                  <div class="title">Income</div>
                  <div class="amount">{{currency}} {{drawResult.income / 100 | number:'1.0-1'}}</div>
                </div>
                <div class="item">
                  <div class="title">Total prize fund</div>
                  <div class="amount">{{currency}} {{drawResult.totalPrizeFund / 100 | number:'1.0-1'}}</div>
                </div>
                <div class="item">
                  <div class="title">Excess</div>
                  <div class="amount">{{currency}} {{drawResult.excess / 100 | number:'1.0-1'}}</div>
                </div>
                <div class="item">
                  <div class="title">Charity</div>
                  <div class="amount">{{currency}} {{drawResult.charity / 100 | number:'1.0-1'}}</div>
                </div>
                <div class="item">
                  <div class="title">Total wins</div>
                  <div class="amount">{{totalWinners}}</div>
                </div>
                <div class="item">
                  <div class="title">Rollover position</div>
                  <div class="amount">{{drawResult.prizeTiers[0].currentRolloverSequenceIndex}}/{{drawResult.prizeTiers[0].rolloverDraws}}</div>
                </div>
                <div class="item">
                  <div class="title">Rollover amount</div>
                  <div class="amount">{{currency}} {{drawResult.prizeTiers[0].currentRolloverAmount / 100 | number:'1.0-1'}}</div>
                </div>
                <div>
                  <div class="title">Winning numbers</div>
                  <div class="winningNumbersDisplay">
                    <div *ngFor="let ballT of gameDetails.ballTypes; index as i; first as isFirst; odd as isOdd; even as isEven; last as isLast">
                      <div *ngFor="let number of formatWinNum(i); index as j">
                        <ng-container *ngIf="!isFirst && !isLast; else standardBalls">
                          <div *ngIf="isOdd" class="odd Ball">
                            {{number}}
                          </div>
                          <div *ngIf="isEven" class="even Ball">
                            {{number}}
                          </div>
                        </ng-container>
                        <ng-template #standardBalls>
                          <div *ngIf="isFirst" class="first Ball">
                            {{number}}
                          </div>
                          <div *ngIf="isLast && !isFirst" class="last Ball">
                            {{number}}
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="widget--footer">
          </div>
        </div>
      </div>


      <div class="widget--grid charts">


        <ng-container *ngIf="drawResult == null && !isLoadingGameDetails && !isLoadingHistoricDraws && historicDrawList && historicDrawList[0]">
          <div class="widget--item prizeBreak">
            <div class="widget--header">
              <div class="title">
                Previous Game
                <span *ngIf="historicDrawList.length > 1" class="nextButton">
                  <button [disabled]="isLoadingDrawResult" mat-mini-fab color="primary" aria-label="Show next draw." (click)="selectNextHistoricDraw()">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </button>
                </span>
              </div>
              <div class="date">{{historicDrawList[selectedHistoricDrawIndex].drawDate | date: 'dd-MM-YYYY, h:mm a'}}</div>
            </div>
            <div class="widget--body">
              <h2>Awaiting results.</h2>
            </div>
            <div class="widget--footer">
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="drawResult && gameDetails && !isLoadingGameDetails && !isLoadingHistoricDraws && historicDrawList">
          <div class="widget--item span--2.5">
            <div class="widget--header">
              <div class="title">
                Previous Game
                <span *ngIf="historicDrawList.length > 1" class="nextButton">
                  <button [disabled]="isLoadingDrawResult" mat-mini-fab color="primary" aria-label="Show next draw." (click)="selectNextHistoricDraw()">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </button>
                </span>
                <span *ngIf="drawResult.isProcessed" class="nextButton">
                  <button mat-mini-fab color="primary" aria-label="Show more information." (click)="setShowExtraInfo(true)">
                    <mat-icon>more_horiz</mat-icon>
                  </button>
                </span>
              </div>
              <div class="date">{{historicDrawList[selectedHistoricDrawIndex].drawDate | date: 'dd-MM-YYYY, h:mm a'}}</div>
            </div>
            <div class="widget--body">
              <div *ngIf="isLoadingDrawResult">
                <app-mat-spinner-overlay [overlay]="false">
                </app-mat-spinner-overlay>
              </div>
              <div *ngIf="!isLoadingDrawResult">
                <app-prize-breakdown [game]="gameDetails" [drawResult]="drawResult"></app-prize-breakdown>
              </div>
            </div>
            <div class="widget--footer">
            </div>
          </div>
        </ng-container>

        <div class="widget--item span--2.5" *ngIf="drawList && gameDetails && !isLoadingGameDetails && !isLoadingDraws && !isLoading">
          <div class="widget--header">
            <div class="title">
              Current Game
              <span *ngIf="drawList.length > 1" class="nextButton">
                <button [disabled]="isLoadingFutureDraw" mat-mini-fab color="primary" aria-label="Show next draw." (click)="selectNextDraw()">
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
              </span>
            </div>
            <div class="date">{{drawList[selectedDrawIndex].drawDate | date: 'dd-MM-YYYY, h:mm a'}}</div>
          </div>
          <div class="widget--body">
            <div *ngIf="isLoadingFutureDraw">
              <app-mat-spinner-overlay [overlay]="false">
              </app-mat-spinner-overlay>
            </div>
            <div *ngIf="!isLoadingFutureDraw">
              <app-prize-breakdown [game]="gameDetails" [draw]="drawList[selectedDrawIndex]"></app-prize-breakdown>
            </div>
          </div>
          <div class="widget--footer">
          </div>
        </div>

        <div class="widget--item" *ngIf="drawResult == null">
        </div>


        <!--<div class="widget--item span--2">
    <div class="widget--header">
      <div class="title">Sales</div>
      <div class="date">{{today | date: 'dd-MM-YYYY'}}</div>
    </div>
    <div class="widget--body">
      <highcharts-chart [Highcharts]="Highcharts" [options]="line1"
                        style="width: 100%; height: 400px; display: block;"></highcharts-chart>
    </div>
    <div class="widget--footer">
    </div>
  </div>-->


        <div class="widget--item" *ngIf="currentDraw?.drawID">
          <div class="widget--header">
            <div class="title">Prize Breakdowns</div>
            <div class="date">{{today | date: 'dd-MM-YYYY'}}</div>
          </div>
          <div class="widget--body">
            <app-prize-chart [chartData]="prizeBreakdowns" [drawID]="currentDraw?.drawID"></app-prize-chart>
          </div>
          <div class="widget--footer">
          </div>
        </div>



        <!--<div class="widget--item">
          <div class="widget--header">
            <div class="title">Total Claims</div>
            <div class="date">{{today | date: 'dd-MM-YYYY'}}</div>
          </div>
          <div class="widget--body">
            <app-claims-graph></app-claims-graph>
          </div>
          <div class="widget--footer">

          </div>
        </div>-->


      </div>
    </div>
    <!-- <div class="main-content">
      <div class="dashboard--wrapper content">
        <mat-grid-list cols="2" rowHeight="2:1">
          <mat-grid-tile *ngFor="let navItem of navItems">
            <button *ngIf="navItem.url !== 'logout'" class="nav-item" mat-raised-button color="primary"
              (click)="navigateTo(navItem.url)">
              <mat-icon aria-hidden="false" aria-label="dashboard icon" fontIcon="{{navItem.icon}}"
                [inline]="true">{{navItem.icon}}</mat-icon>
              <div class="title">{{navItem.name}}</div>
            </button>
            <div *ngIf="navItem.url == 'logout'" style="width: 100%; height: 100%; float: right;">
              <button class="nav-item-logout" mat-stroked-button color="link" (click)="navigateTo(navItem.url)">
                <mat-icon aria-hidden="false" aria-label="dashboard icon" fontIcon="{{navItem.icon}}"
                  [inline]="true">{{navItem.icon}}</mat-icon>
                <div class="title">{{navItem.name}}</div>
              </button>
            </div>
          </mat-grid-tile>
        </mat-grid-list>

      </div>
    </div> -->
  </div>
  <div class="main--content" *ngIf="currentGame == undefined && !isLoading">
    <div class="widget--grid charts">
      <div class="widget--item span--5">
        <div class="widget--header">
          <div class="title">
            No games found.
          </div>
          <div class="date"></div>
        </div>
        <div class="widget--body">
          <div class="details--list">
            <div class="item">
              <div class="amount">There are currently no live games to show. </div>
            </div>
          </div>
        </div>
        <div class="widget--footer">
        </div>
      </div>
    </div>
  </div>


</mat-drawer-container>
