<div *ngIf="game">

  <form [formGroup]="prizeTierForms">

    <div formArrayName="prizeTierFormsList">


      <ng-container *ngFor="let prizeTier of prizeTierFormsList.controls; index as i;">
        <div *ngIf="game.prizeTiers[i] != undefined">
          <ng-container *ngFor="let req of game.prizeTiers[i].requirements; index as j; first as isFirst; odd as isOdd; even as isEven; last as isLast">
            <ng-container *ngIf="!isFirst && !isLast; else standardBalls">
              <div *ngIf="isOdd" class="odd Ball">
                {{req.matches}}
              </div>
              <div *ngIf="isEven" class="even Ball">
                {{req.matches}}
              </div>
            </ng-container>
            <ng-template #standardBalls>
              <div *ngIf="isFirst" class="first Ball">
                {{req.matches}}
              </div>
              <div *ngIf="isLast && !isFirst" class="last Ball">
                {{req.matches}}
              </div>
            </ng-template>
          </ng-container>
        </div>
        <div [formGroupName]="i">

          <mat-form-field appearance="outline">
            <mat-label>Prize fund percentage:</mat-label>
            <input matInput type="text" formControlName="prizeFundPercentage" suffix="%" mask="percent.2">

          </mat-form-field>

          <!--<mat-form-field appearance="outline">
      <mat-label>Fixed prize per line:</mat-label>
      <input matInput type="number" formControlName="fixedPrizePerLine">
    </mat-form-field>-->

          <mat-form-field appearance="outline">
            <mat-label>Fixed limit per draw:</mat-label>
            <input matInput type="text" formControlName="fixedLimitPerDraw" [prefix]="currency" mask="separator.2">

          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Rollover Draws:</mat-label>
            <input matInput type="number" formControlName="rolloverDraws">
          </mat-form-field>
          <div *ngIf="!this.disable" style="display: inline-block">
            <button *ngIf="!this.game.prizeTiers[i]?.prizeTierID" mat-button class="btn btn-success" [disabled]="!(prizeTier.valid && prizeTier.dirty)" (click)="submitPrizeTier(prizeTier)">Submit</button>
            <button *ngIf="this.game.prizeTiers[i]?.prizeTierID" mat-button class="btn btn-success" [disabled]="!(prizeTier.valid && prizeTier.dirty)" (click)="updatePrizeTier(prizeTier, i)">Update</button>
            <mat-icon (click)="removePrizeTier(i)">delete</mat-icon>
          </div>
          <!--<button mat-button type="button" class="btn btn-default" (click)="debug(prizeTier)">debug</button>-->
          <div *ngIf=" game.isPublished">

            <mat-form-field appearance="outline">
              <mat-label>Probability:</mat-label>
              <input matInput type="number" value="{{game.prizeTiers[i].probability}}" [disabled]="true">
            </mat-form-field>
            <!--<span class="grey">{{game.prizeTiers[i].probability | percent:'1.3-10'}}</span>-->
            <span class="grey">1 in {{1 / game.prizeTiers[i].probability | number:'1.0-0'}} </span>
            <div *ngIf="game.prizeTiers[i].rolloverDraws > 0">
              <mat-form-field appearance="outline">
                <mat-label>Current Rollover Amount:</mat-label>
                <input matInput type="text" formControlName="currentRolloverAmount" value="{{game.prizeTiers[i].currentRolloverAmount}}" [prefix]="currency" mask="separator.2">
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Current Rollover Index:</mat-label>
                <input matInput type="number" value="{{game.prizeTiers[i].currentRolloverSequenceIndex}}" [disabled]="true">
              </mat-form-field>
            </div>
          </div>
          <div formArrayName="requirements">
            <ng-container *ngFor="let req of getRequirements(i).controls; index as j;">
              <div [formGroupName]="j">

                <mat-form-field appearance="outline">
                  <mat-label>Ball type:</mat-label>
                  <input matInput type="text" value="{{game.ballTypes[j].name}}" [disabled]="true">
                </mat-form-field>


                <!--<mat-form-field appearance="outline">
            <mat-label>ID:</mat-label>
            <input matInput type="number" formControlName="ballTypeID">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>ordinal:</mat-label>
            <input matInput type="number" formControlName="ordinal">
          </mat-form-field>-->

                <mat-form-field appearance="outline">
                  <mat-label>Matches</mat-label>
                  <mat-select formControlName="matches">
                    <mat-option [value]=0>None</mat-option>
                    <mat-option *ngFor="let number of getBallMatchOptions(j)" [value]="number + 1">
                      {{number + 1}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>
            </ng-container>
          </div>

        </div>
        <mat-divider></mat-divider>
      </ng-container>
      <div *ngIf="!this.disable">
        <button mat-button type="button" class="btn btn-default" (click)="addPrizeTier()">Add new prize tier</button>

      </div>
    </div>
  </form>

</div>
