
<div *ngIf="game?.isPublished">
  <div [formGroup]="bonusDetailForm">
    <mat-form-field appearance="outline">
      <mat-label>Excess balance:</mat-label>
      <input matInput type="text" formControlName="excessBalance" [prefix]="currency" mask="separator.2">
    </mat-form-field>
  </div>
</div>

<form [formGroup]="gameForm" (ngSubmit)="onSubmit()">

  <mat-form-field appearance="outline">
    <mat-label>Title:</mat-label>
    <input matInput formControlName="title">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Price per line:</mat-label>

    <input matInput type="text" formControlName="pricePerLine" [prefix]="currency" mask="separator.2">
    <!--<div matSuffix class="grey">K</div>-->
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Prize fund percentage:</mat-label>
    <input matInput type="text" formControlName="prizeFundPercentage" suffix="%" mask="percent.2">
    <!--<div matSuffix class="grey">%</div>-->
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Round prizes to:</mat-label>
    <input matInput type="text" formControlName="roundPrizesTo" [prefix]="currency" mask="separator.2">
    <mat-hint *ngIf="!disable">Max: {{currency}}10000.00</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Central payout threshold:</mat-label>
    <input matInput type="text" formControlName="centralPayoutThreshold" [prefix]="currency" mask="separator.2">
  </mat-form-field>

  <mat-form-field appearance="outline" style="min-width: 402px">
    <mat-label>Description:</mat-label>
    <textarea matInput cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="5" placeholder="Ex. Fun friday lottery..." formControlName="description"></textarea>
  </mat-form-field>
  <div formArrayName="ballTypes">
    <ng-container *ngFor="let ball of ballTypes.controls; index as i;">

      <div [formGroupName]="i">
        <mat-form-field appearance="outline">
          <mat-label>Ball name:</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Ordinal:</mat-label>
          <input matInput type="number" formControlName="ordinal">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Number of balls:</mat-label>
          <input matInput type="number" formControlName="numberOfBalls">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Number of options:</mat-label>
          <input matInput type="number" formControlName="numberOfOptions">
        </mat-form-field>
        <ng-container *ngIf="!this.disable ">
          <mat-icon *ngIf="i!=0" (click)="removeBallType(i)">remove_circle_outline</mat-icon>
        </ng-container>


      </div>


    </ng-container>
  </div>
  <div *ngIf="!this.disable">
    <button mat-button type="button" class="btn btn-default" (click)="addBallType(null)">Add new ball</button>
    <button mat-button type="submit" class="btn btn-success" [disabled]="!gameForm.valid">Submit</button>
    <button mat-button type="button" class="btn btn-default" (click)="gameForm.reset()">Reset</button>
  </div>
</form>
<!--<button mat-button type="button" class="btn btn-default" (click)="debug()">debug</button>-->
<mat-divider></mat-divider>
