import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Organisation, Region, UserInfo } from '../../models/onboarding.model';
import { ApiService } from '../../services/api.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-users',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  isLoading = true;

  selected: Organisation = {} as Organisation;
  selectedUser: UserInfo = {} as UserInfo;
  selectedRegions: Region[] = [];
  showNewOrgForm = false;
  role: string = this._nav.userProfile.claims;
  orgManJourney: boolean = false;

  dataSource!: MatTableDataSource<Organisation>;
  displayedColumns: string[] = ['id', 'name', 'reference', 'posCount'];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('drawer') menu!: MatDrawer;

  constructor(private _api: ApiService, private _nav: NavService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    if (this.role.includes('rolemaster')) {
      this.getOrganisations();
    } else {
      /*      this.selected = { organisationID: this._nav.userProfile.domainID, name: this._nav.userProfile.domain, contactDetail: new() , posCount: undefined }*/
      this.orgManJourney = true;
      this.selected.organisationID = this._nav.userProfile.domainID;
      this.selected.name = this._nav.userProfile.domain;
      this.isLoading = false;
    }

  }

  getOrganisations() {
    this.isLoading = true;
    this._api.getOrganisations().subscribe(result => {
      this.dataSource = new MatTableDataSource(result.items);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoading = false;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  select(org: Organisation) {
    this.selected = org;

    //this._api.getRegions(this.selected.organisationID).subscribe(result => {
    //  this.selectedRegions = result.items;
    //})
  }


  addNewOrg() {
    this.showNewOrgForm = !this.showNewOrgForm;
  }

  toggleMenu() {
    this.menu.toggle();
  }


  orgAdded(orgName: string) {
    this._snackBar.open(orgName + " has been added!", 'X');
    this.getOrganisations();
    this.showNewOrgForm = false;
  }

}
