<mat-card *ngIf="dataSource !== null">
  <div class="title"><h1>Points of Sale</h1></div>

  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">


    <ng-container matColumnDef="pointOfSaleID">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let element"> {{element.pointOfSaleID}} </td>
    </ng-container>


    <!--<ng-container matColumnDef="emailAddress">
      <th mat-header-cell *matHeaderCellDef> Email Adress </th>
      <td mat-cell *matCellDef="let element"> {{element.contactDetail.emailAddress}} </td>
    </ng-container>-->
    <ng-container matColumnDef="reference">
      <th mat-header-cell *matHeaderCellDef> Reference </th>
      <td mat-cell *matCellDef="let element"> {{element.reference}} </td>
    </ng-container>


    <ng-container matColumnDef="addedOn">
      <th mat-header-cell *matHeaderCellDef> Added On</th>
      <td mat-cell *matCellDef="let element"> {{element.addedOn | date}} </td>
    </ng-container>




    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>
  <mat-divider></mat-divider>
  <div>
    <mat-form-field appearance="outline" style="display:inline-flex">
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
    </mat-form-field>
    <mat-paginator  style="display:inline-flex" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of organisations"></mat-paginator>
    <button *ngIf="!showAddPosForm" mat-button type="button" class="btn btn-default" (click)="addNewPos()">New</button>
    <button *ngIf="showAddPosForm" mat-button color="warn" type="button" class="btn btn-default" (click)="addNewPos()">Cancel</button>
  </div>
  

  <app-pos-form *ngIf="showAddPosForm"  [selectedID]="selectedID"></app-pos-form>
</mat-card>
