<div class="widget--grid">
  <div class="widget--item">
    <div>
      <div class="widget--header">
        <h1>Add new Point of sale</h1>
      </div>
      <div class="widget--body"></div>
      <form [formGroup]="userForm" (ngSubmit)="onSubmit()">

        <mat-form-field appearance="outline">
          <mat-label>Reference</mat-label>
          <input matInput formControlName="reference">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Location Description</mat-label>
          <input matInput formControlName="locationDescription">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Username</mat-label>
          <input matInput formControlName="username">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="passwordB64">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Confirm Password</mat-label>
          <input matInput type="password" formControlName="passwordB642">
        </mat-form-field>

        <button mat-button type="submit" class="btn btn-success" [disabled]="!userForm.valid">Submit</button>
        <button mat-button type="button" class="btn btn-default" (click)="userForm.reset()">Reset</button>
      </form>
      <div *ngIf="errorMsg">
        <div class="details--list">
          <div class="item">
            <div class="title">Error</div>
            <div class="amount">{{errorMsg}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
