<div class="widget--grid">
  <div class="widget--item">
    <div>
      <div class="widget--header">
        <h1>Add New {{ListType[mode].name}}</h1>
      </div>
      <div class="widget--body"></div>
    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">

      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Username</mat-label>
        <input matInput formControlName="username">
      </mat-form-field>

      <!--<mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input matInput type="email" formControlName="email">
  </mat-form-field>-->

      <mat-form-field appearance="outline">
        <mat-label>Phone number</mat-label>
        <input matInput type="tel" formControlName="phone" mask="999999999999999">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="passwordB64">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Confirm Password</mat-label>
        <input matInput type="password" formControlName="passwordB642">
      </mat-form-field>

      <button mat-button type="submit" class="btn btn-success" [disabled]="!userForm.valid">Submit</button>
      <button mat-button type="button" class="btn btn-default" (click)="userForm.reset()">Reset</button>
    </form>
      <div *ngIf="errorMsg">
        <div class="details--list">
          <div class="item">
            <div class="title">Error</div>
            <div class="amount">{{errorMsg}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
