import { Component, Input } from '@angular/core';
import { UserInfo } from '../../models/onboarding.model';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-role-manager',
  templateUrl: './role-manager.component.html',
  styleUrls: ['./role-manager.component.scss']
})
export class RoleManagerComponent {

  constructor(private _api: ApiService) { }

  @Input() userID!: number;
  selectedUser: UserInfo | undefined;
  activeRoles: string[] = [];
  roles: any[] | undefined;
  isLoading: boolean = false;
  errorMsg: string | undefined;

  ngOnInit(): void {
    this.load();
  }

  ngOnChanges(): void {
    this.selectedUser = undefined;
    this.activeRoles = [];
    this.load();
  }

  load() {
    this._api.getUserAdmin(this.userID).subscribe(result => {
      this.selectedUser = result;
      this.selectedUser.claims.forEach(claim => {
        if (claim.key == "role") {
          this.activeRoles.push(claim.value);
        }
      })
    });
    if (this.roles == undefined) {
      this._api.getRoles().subscribe(result => {
        this.roles = result;
      })
    }
    
  }

  onRoleSelect(role: string) {
    //remove role
    this.isLoading = true;
    if (!this.activeRoles.includes(role)) {
      this._api.removeRole(role, this.userID).subscribe({
        next: (res) => {
          this.isLoading = false;
        },
        error: (err) => {
          console.log("ERROR: ", err);
          this.errorMsg = err
        }
      })
    }
    //add role
    else {
      this._api.addRole(role, this.userID).subscribe({
        next: (res) => {
          this.isLoading = false;
        },
        error: (err) => {
          console.log("ERROR: ", err);
          this.errorMsg = err
        }
      })
    }
    
  }
}
