<mat-card *ngIf="dataSource !== null">
  <div class="title"><h1>Users</h1></div>
  <ng-container *ngIf="selectedUser && rolemaster">
  <app-role-manager [userID]="this.selectedUser.userID"></app-role-manager>
</ng-container>

  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">


    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>


    <!--<ng-container matColumnDef="emailAddress">
      <th mat-header-cell *matHeaderCellDef> Email Adress </th>
      <td mat-cell *matCellDef="let element"> {{element.contactDetail.emailAddress}} </td>
    </ng-container>-->
    <!--<ng-container matColumnDef="phoneNumber">
      <th mat-header-cell *matHeaderCellDef> Phone Number </th>
      <td mat-cell *matCellDef="let element"> {{element.contactDetail.phoneNumber}} </td>
    </ng-container>-->


    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef> Username</th>
      <td mat-cell *matCellDef="let element"> {{element.username}} </td>
    </ng-container>




    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)" [ngClass]="{ 'selected': selectedUser == row}"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>
  <mat-divider></mat-divider>
  <div>
    <mat-form-field appearance="outline" style="display:inline-flex">
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
    </mat-form-field>
    <mat-paginator style="display:inline-flex" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of organisations"></mat-paginator>
    <button *ngIf="!showAddUserForm" mat-button type="button" class="btn btn-default" (click)="addNewUser()">New User</button>
    <button *ngIf="!showAddUserForm" mat-button type="button" class="btn btn-default" (click)="addNewManager()">New Manager</button>
    <button *ngIf="showAddUserForm" mat-button color="warn" type="button" class="btn btn-default" (click)="addNewUser()">Cancel</button>
  </div>


  <app-user-form *ngIf="showAddUserForm" [mode]="addManagerMode" [selectedID]="selectedID"></app-user-form>
</mat-card>
