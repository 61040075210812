<div class="navbar--wrapper">
  <div>
    <div>
      <button mat-fab color="primary" aria-label="Show menu" (click)="toggleMenu()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </div>
    <div class="left-col">
        <div class="title--wrapper">{{title}}</div>
    </div>
    <div class="right-col">
        <div class="initials">
            {{initials}}
        </div>
    </div>
</div>
