<div class="sidebar--wrapper">
    <div class="logo--wrapper">
        <div class="logo">
            <img src="../../../assets/icon/Lottery logo LOGIN.svg">
        </div>
    </div>

    <div class="nav-items--wrapper">
        <div class="nav-items--list">
            <div class="nav-item" *ngFor="let navItem of navItems" (click)="navigateTo(navItem.url)">
                <mat-icon aria-hidden="false" aria-label="dashboard icon" fontIcon="{{navItem.icon}}">{{navItem.icon}}</mat-icon>
                <div class="title">{{navItem.name}}</div>
            </div>
        </div>
    </div>
</div>
