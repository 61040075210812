import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators, ReactiveFormsModule, FormBuilder, FormArray } from '@angular/forms';
import { BallTypeAddOptions, GameAddOptions, GameListItem, GameSetup } from '../../models/games.model';
import { ApiService } from '../../services/api.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-game-form',
  templateUrl: './game-form.component.html',
  styleUrls: ['./game-form.component.scss']
})
export class GameFormComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private api: ApiService) { }

  @Input() gameID!: number;
  @Input() game: GameSetup | undefined;

  @Output() refreshGames = new EventEmitter<GameSetup>();

  submitted = false;
  gameForm!: FormGroup;
  bonusDetailForm!: FormGroup;
  disable = false;
  currency = environment.currency;

  ngOnInit(): void {
    this.generateGameForm();
  }

  ngOnChanges() {
    this.generateGameForm();
  }   

  generateGameForm() {
    //If isPublished disable forms
    if (this.game) {
      this.disable = this.game !== undefined;
      this.bonusDetailForm = this.formBuilder.group({
        excessBalance: new FormControl({ value: this.getBigDenomination(this.game.excessBalance), disabled: this.disable }),
      });
    } else {
      this.disable = false;
    }


    this.gameForm = this.formBuilder.group({
      title: new FormControl({ value: '', disabled: this.disable }, [Validators.required, Validators.maxLength(36)]),
      description: new FormControl({ value: '', disabled: this.disable }, [Validators.required, Validators.maxLength(256)]),
      pricePerLine: new FormControl({ value: '', disabled: this.disable }, [Validators.min(1), Validators.max(2147483647)]),
      prizeFundPercentage: new FormControl({ value: '', disabled: this.disable }, [Validators.min(1), Validators.max(100)]),
      roundPrizesTo: new FormControl({ value: '', disabled: this.disable }, [Validators.min(0), Validators.max(1000000)]),
      centralPayoutThreshold: new FormControl({ value: '', disabled: this.disable }, [Validators.required, Validators.min(0), Validators.max(999999999999)]),
      ballTypes: this.formBuilder.array([]),
    });

    if (this.game) {
      //Adjust currency values to Naira.
      this.gameForm.patchValue({
        title: this.game.title,
        description: this.game.description,
        pricePerLine: this.getBigDenomination(this.game.pricePerTicket),
        prizeFundPercentage: this.game.prizeFundPercentage,
        roundPrizesTo: this.getBigDenomination(this.game.roundPrizesTo),
        centralPayoutThreshold: this.getBigDenomination(this.game.centralPayoutThreshold)
      });

      this.game.ballTypes.forEach((ballType) => {
        this.addBallType({
          name: ballType.name,
          ordinal: ballType.ordinal,
          numberOfBalls: ballType.numberOfBalls,
          numberOfOptions: ballType.numberOfOptions
        });
      })
    } else {
      //Add the one minimum ball type.
      this.addBallType(null);
    }
  }

  onSubmit() {
    if (this.gameForm.valid) {
      let data = this.gameForm.getRawValue();
      //Adjust to Kobo;
      data.pricePerLine = this.getSmallDenomination(data.pricePerLine);
      data.roundPrizesTo = this.getSmallDenomination(data.roundPrizesTo);
      data.centralPayoutThreshold = this.getSmallDenomination(data.centralPayoutThreshold);
      this.api.addGameDraft(data).subscribe({
        next: (response) => {
          this.refreshGames.emit(response);
        },
        error: (error) => {
          //TODO handle error
        }
      })
    }
  }

  get ballTypes() {
    return this.gameForm.get('ballTypes') as FormArray;
  }

  addBallType(data: any): void {
    this.ballTypes.push(this.setBallType(data));
  }

  setBallType(data: any = null) {
    data = data || { name: null, ordinal: (this.ballTypes.controls.length + 1), numberOfBalls: null, numberOfOptions: null}
    return new FormGroup({
      name: new FormControl({ value: data.name, disabled: this.disable }, [Validators.required, Validators.maxLength(36)]),
      ordinal: new FormControl({ value: data.ordinal, disabled: true }, [Validators.required, Validators.pattern('[- +()0-9]+')]),
      numberOfBalls: new FormControl({ value: data.numberOfBalls, disabled: this.disable }, [Validators.min(1), Validators.max(255), Validators.pattern('[- +()0-9]+')]),
      numberOfOptions: new FormControl({ value: data.numberOfOptions, disabled: this.disable }, [Validators.min(2), Validators.max(255), Validators.pattern('[- +()0-9]+')]),
    })
  }

  removeBallType(pos: number) {
    this.ballTypes.removeAt(pos);
    this.fixBallOrdinals();
  }

  fixBallOrdinals() {
    this.ballTypes.controls.forEach((ballType, index) => {
      ballType.patchValue({
        ordinal: (index + 1)
      })
    })
  }

  getSmallDenomination(big: number) {
    return big * 100;
  }

  getBigDenomination(small: number) {
    return small / 100;
  }

  debug() {
    console.log("game form: ", this.gameForm);
    console.log("game form: ", this.gameForm.getRawValue());
  }
}
