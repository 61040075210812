import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  userProfile = {
    name: '',
    initials: '',
    userID: 0,
    role: '',
    domain: '',
    domainID: 0,
    claims: <string><unknown>[]
  };
  constructor() { }
}
