import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserInfo } from '../../models/onboarding.model';
import { ApiService } from '../../services/api.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  dataSource!: MatTableDataSource<UserInfo>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = ['name', 'username'];
  showAddUserForm = false;

  constructor(private _api: ApiService, private _nav: NavService) { }

  //0 = rolemaster, 1 = orgMan.
  @Input() mode!: number;

  @Input() selectedID!: number;

  selectedUser?: UserInfo;

  rolemaster: boolean = false;
  

  addManagerMode: number = 0;

  ngOnInit(): void {
    if (this._nav.userProfile.claims.includes("rolemaster")) {
      this.rolemaster = true;
    }
    this.load();
  }
  ngOnChanges(): void {
    this.load();
  }

  load() {
      this.getOrganisationUsers();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  select(user: any) {
    this.selectedUser = user;
  }

  addNewUser() {
    this.addManagerMode = 0;
    this.showAddUserForm = !this.showAddUserForm;
  }

  addNewManager() {
    this.addManagerMode = 1;
    this.showAddUserForm = !this.showAddUserForm;
  }

  getOrganisationUsers() {
    this._api.getOrganisationUsersAdmin(this.selectedID).subscribe(result => {
      this.dataSource = new MatTableDataSource(result.items);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }


}
