
<div *ngIf="!loadingForm">

  <app-auto-refresh (TimerExpired)="getDrawStatus()"></app-auto-refresh>
  <mat-progress-bar *ngIf="drawState?.isPublished == false || drawState == undefined" mode="query"></mat-progress-bar>
  <div style="text-align: center;">
    <p>Two matching number sets must be submitted to publish the draw result.</p>
  </div>
  <div *ngIf="drawState == undefined" style="text-align: center;">
    <p>No numbers have been submitted yet.</p>
  </div>
  <div *ngIf="drawState && drawState?.addedBy !== userID" style="text-align: center;">
    <p>The first set of winning numbers have been entered.</p>
  </div>

  <form *ngIf="drawState?.addedBy !== userID; else undoBlock" [formGroup]="winningNumbersForm" (ngSubmit)="onSubmit()">
    <!--<pre>Form values: {{ winningNumbersForm.value | json }}</pre>-->
    <ng-container *ngFor="let balltype of gameDetails.ballTypes; index as i">

      <ng-container *ngFor="let item of getBallMatchOptions(balltype.numberOfBalls); index as j;">

        <mat-form-field appearance="outline">
          <mat-label>{{balltype.name + " " + (j + 1)}}</mat-label>
          <mat-select formControlName="{{balltype.name + (j + 1)}}">

            <mat-option *ngFor="let number of getBallMatchOptions(balltype.numberOfOptions, j+1 , balltype)" [value]="number + 1">
              {{number + 1}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

    </ng-container>


    <button mat-button type="submit" class="btn btn-success" [disabled]="!winningNumbersForm.valid">Submit</button>
    <button mat-button type="button" class="btn btn-default" (click)="winningNumbersForm.reset()">Reset</button>

  </form>
  <ng-template #undoBlock>
    <div style="text-align: center;">
      <p><b>You have submitted the first set of winning numbers.</b></p>
    </div>
    <button mat-raised-button [disabled]="disableResultButton" color="warn" type="button" class="btn btn-default" style="float:right" (click)="undoSubmit()"><mat-icon>warning</mat-icon>Undo Submit</button>
  </ng-template>
</div>
