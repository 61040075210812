<div class="page--wrapper">
  <div class="column column--left">
    <app-sidebar></app-sidebar>
  </div>
  <div class="column column--right">

    <app-navbar title="Organisations"></app-navbar>
    <!--<app-mat-spinner-overlay *ngIf="isLoading" [overlay]="true">
  </app-mat-spinner-overlay>-->
    <div class="main--content">
      <div class="dashboard--wrapper content">
        <mat-card>
          <mat-form-field>
            <mat-label>Search</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
          </mat-form-field>
          <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef> ID </th>
              <td mat-cell *matCellDef="let element"> {{element.organisationID}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="posCount">
              <th mat-header-cell *matHeaderCellDef> Total PoS </th>
              <td mat-cell *matCellDef="let element"> {{element.posCount}} </td>
            </ng-container>

            <ng-container matColumnDef="reference">
              <th mat-header-cell *matHeaderCellDef> Reference </th>
              <td mat-cell *matCellDef="let element"> {{element.reference}} </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
          </table>
          {{selected | json}}
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of organisations"></mat-paginator>
          <button *ngIf="!showNewOrgForm" mat-button type="button" class="btn btn-default" (click)="addNewOrg()">New</button>
          <button *ngIf="showNewOrgForm" mat-button type="button" class="btn btn-default" (click)="addNewOrg()">Cancel</button>
        </mat-card>
      </div>
      <div *ngIf="showNewOrgForm" class="dashboard--wrapper content">

        <div>
          <app-organisation-form></app-organisation-form>
        </div>
      </div>
      <div class="dashboard--wrapper content">
        <app-user-list *ngIf="this.selected.organisationID" [mode]="0" [selectedID]="this.selected.organisationID"></app-user-list>
      </div>
    </div>

  </div>


</div>
