import { NgModule } from '@angular/core';
import { Navigation, RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { GamesComponent } from './components/games/games.component';
import { LoginComponent } from './components/login/login.component';
import { OrganisationsComponent } from './components/organisations/organisations.component';
import { ReportsComponent } from './components/reports/reports.component';
import { TicketsComponent } from './components/tickets/tickets.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { AuthguardService } from './services/authguard.service';


const routes: Routes = [

  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthguardService]
  },
  {
    path: 'games',
    component: GamesComponent,
    canActivate: [AuthguardService]
  },
  {
    path: 'users',
    component: UserMenuComponent,
    canActivate: [AuthguardService]
  },
  {
    path: 'tickets',
    component: TicketsComponent,
    canActivate: [AuthguardService]
  },
  {
    path: 'organisations',
    component: OrganisationsComponent,
    canActivate: [AuthguardService]
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthguardService]
  },
  {
    path: '**',
    component: LoginComponent,
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
