<mat-card *ngIf="selectedUser !== undefined">
  <div *ngIf="errorMsg">
    {{errorMsg}}
  </div>
    <div class="title"><h1>Modify {{selectedUser.name}}</h1></div>
    Active roles:
    <mat-form-field appearance="outline">
      <mat-label hidden>Select a role</mat-label>
      <mat-select [disabled]="isLoading" [(value)]="activeRoles" multiple placeholder="Select a role" floatlLabel="never">
        <mat-select-trigger>
          <app-mat-spinner-overlay *ngIf="isLoading" [overlay]="false" [value]="10" [diameter]="30" [strokeWidth]="3">
          </app-mat-spinner-overlay>
          <mat-chip-set *ngIf="!isLoading">
            <mat-chip-row color="primary" *ngFor="let claim of activeRoles" highlighted>{{claim}}</mat-chip-row>
          </mat-chip-set>
        </mat-select-trigger>
        <mat-option [disabled]="isLoading" (click)="onRoleSelect(role)" *ngFor="let role of roles" [value]="role">{{role}}</mat-option>
      </mat-select>
    </mat-form-field>
</mat-card>
