import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { NavService } from './nav.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  constructor(
    private _apiService: ApiService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _nav: NavService
  ) { }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000
    });
  }

  login(username: string, password: string) {
    // TODO: Error handler
    if (username && password) {
      const domRegex = /(?<=@).*/gm;
      const loginNameRegex = /^.*?(?=@)/gm;

      let loginName: string = username.match(loginNameRegex)?.toString() || '';
      let dom: string = username.match(domRegex)?.toString() || '';

      this._apiService.login(loginName, dom, password).subscribe(
        results => {
          sessionStorage.setItem('__token', JSON.stringify(results));
          this._router.navigate(['/dashboard']);
        },
        error => {
          this.openSnackBar('Username or password not recognised', 'Close');
          console.log(error);
        });
    } else {
      this.openSnackBar('Please provide a username and password', 'Close');
    }
  }

  logout() {
    sessionStorage.removeItem('__token');
    this._router.navigate(['/login']);
  }

  public getToken(): any | null {
    if (sessionStorage.getItem('__token')) {
      return JSON.parse(sessionStorage.getItem('__token') || '');
    } else {
      return null;
    }
  }

  public isAuthenticated(): boolean {
    // get the token
    const _token = this.getToken();

    if (!_token) {
      return false;
    } else {
      const _name = _token.name;
      const _rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
      const re = /zero:/gi;
      const userDetails = JSON.parse(atob(_token.token.split(".")[1]).replace(re, ""));
      let initials = [..._name.matchAll(_rgx)] || [];
      this._nav.userProfile.initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
      ).toUpperCase();
      this._nav.userProfile.userID = +userDetails.userid;
      this._nav.userProfile.domain = userDetails.dom;
      this._nav.userProfile.name = userDetails.n;
      this._nav.userProfile.domainID = userDetails.domainid;
      //Set roles
        this._nav.userProfile.role = userDetails.drl || userDetails.Role;
        this._nav.userProfile.claims = userDetails.role;

    }
    // TODO; expiry
    // return a boolean reflecting 
    // whether or not the token is expired

    return true;
  }
}
